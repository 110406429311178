.MarketingModule-container-parent-class .campaign_continue_after_failed_container{
    display: flex;
    align-items: center;
    grid-gap: 8px;
    background: var(--gray_light);
    padding: 12px;
    border-radius: 5px;
    justify-content: space-between;
}
.MarketingModule-container-parent-class .campaign_continue_afc_row{
    display: flex;
    align-items: center;
}
.MarketingModule-container-parent-class .campaign_continue_afc_row span{
    display: inline-flex;
}

.MarketingModule-container-parent-class .m-marketing-lead-source .basic_toggle_icon {
     position: absolute;
     top: 20px !important;
     right: 25px !important;
     cursor: pointer;
}

.MarketingModule-container-parent-class .m-marketing-lead-source .remove_border{
    border-bottom: none !important;
}

.MarketingModule-container-parent-class .updated_group_leadFlow_assigned .lead_src_advance .connect_lead_source_title h2 {
    padding: 15px 20px !important;
}

.MarketingModule-container-parent-class .connect_lead_source_title h2 {
    font-size: 15px !important;
}

.MarketingModule-container-parent-class .lead_src_group>ul>li::before {
    font-size: 16px !important;
}

.MarketingModule-container-parent-class .updated_group_leadFlow_assigned .campaign_email_address {
    margin-top: 20px;
}

.MarketingModule-container-parent-class .campaign_connected_email_list_title{
    font-size: 16px !important;
}

.MarketingModule-container-parent-class .campaign_connected_email_list .campaign_connected_email_list_item {
    display: flex;
    gap: 6px;
    font-size: 14px;
    position: relative;
}

.MarketingModule-container-parent-class .campaign_connected_email_list .campaign_connected_email_list_item:hover:not(:first-child) {
    border-bottom: 1px solid rgba(170, 170, 170, 0.29);
    cursor: default;
}

.MarketingModule-container-parent-class .campaign_connected_email_list .campaign_connected_email_list_item:hover .cmp-email-rm-icn{
    display: block;
}

.MarketingModule-container-parent-class .campaign_connected_email_list .campaign_connected_email_list_item .cmp-email-rm-icn{
    display: none;
    position: absolute;
    right: 0;
    top: 4px;
}

/*.MarketingModule-container-parent-class .updated_group_leadFlow_assigned .campaign_details_title {*/
/*    justify-content: start;*/
/*}*/

.MarketingModule-container-parent-class .campaign_connected_email_list .campaign_connected_email_list_item:not(:last-child){
    margin-bottom: 6px;
}

.MarketingModule-container-parent-class .campaign_connected_email_list .campaign_connected_email_list_item .campaign_email_item_icon {
    padding: 2px 0 0 0;
    cursor: pointer;
}

.MarketingModule-container-parent-class .campaign_connected_email_list{
    margin-top: 5px !important;
}

.MarketingModule-container-parent-class .campaign_email_list_header {
    display: flex;
    justify-content: space-between;
}

.MarketingModule-container-parent-class .campaign_email_list_header .add_contact.lead_src_btn {
    width: 156px;
}

.MarketingModule-container-parent-class .btn-text-cmp{
    margin-right: 20px;
}

.MarketingModule-container-parent-class .btn-icn-cmp{
    margin-right: 5px !important;
}

.MarketingModule-container-parent-class .campaign_email_selection_section .lead_src_btn a span {
    margin-right: 5px !important;
}

.MarketingModule-container-parent-class .campaign_phn_no h6, .MarketingModule-container-parent-class .campaign_time_zone h6 {
    font-size: 14px !important;
}

.MarketingModule-container-parent-class .campaign_email_selection_section .add_contact.lead_src_btn{
    width: 25%;
}

.MarketingModule-container-parent-class .campaign_email_list_header .lead_src_btn a{
    padding: 8px 0 !important;
    border: 1px solid #E5E8EF !important;
}

.MarketingModule-container-parent-class .campaign_email_selection_section .lead_src_btn a{
    padding: 8px 0 !important;
    border: 1px solid #E5E8EF !important;
}

.MarketingModule-container-parent-class .campaign_email_selection_section .campaign_phn_no{
    width: 70% !important;
}


.MarketingModule-container-parent-class .campaign_email_selection_section{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.MarketingModule-container-parent-class .cancel_button a {
    background: var(--red);
    color: white;
}

.MarketingModule-container-parent-class span.datepicker__close_btn{
    cursor: pointer;
}

.MarketingModule-container-parent-class span.datepicker__close_btn i.close {
    width: 22px;
    height: 22px;
    border-radius: 11px;
    background: #FF264A;
    color: #fff;
    font-size: 12px;
    line-height: 22px;
    box-shadow: 0 0 1px 1px #ff264a;
    display: block;
    text-align: center;
    z-index: 9;
}

.MarketingModule-container-parent-class .expand-more-wrapper{
    display: flex;
    align-items: baseline;
    cursor: default;
}

.MarketingModule-container-parent-class .expand-more-wrapper .expand-more-text{
    text-transform: uppercase;
    margin-right: 5px;
    color: #0f0fe3;
    font-size: 12px;
}

.MarketingModule-container-parent-class .expand-more-wrapper .expand-more-icon svg{
    height: 8px;
}

.MarketingModule-container-parent-class .expand-less-wrapper{
    display: flex;
    align-items: baseline;
    cursor: default;
}

.MarketingModule-container-parent-class .expand-less-wrapper .expand-less-text{
    text-transform: uppercase;
    margin-right: 5px;
    color: #0f0fe3;
    font-size: 12px;
}

.MarketingModule-container-parent-class .expand-less-wrapper .expand-less-icon svg{
    height: 8px;
}

@media screen and (max-width: 1324px){
    .MarketingModule-container-parent-class .updated_group_leadFlow_assigned .campaign_details_content .input-field label {
        font-size: 16px !important;
    }

    .MarketingModule-container-parent-class .campaign_bottom_buttons .start_button a, .campaign_bottom_buttons .delete_button a {
        height: 42px;
        font-size: 13px;
    }
}

@media (max-width: 1436px){
    .MarketingModule-container-parent-class .updated_group_leadFlow_assigned .number_count_btn {
        padding: 4px !important;
    }

    .MarketingModule-container-parent-class .campaign_email_list_header .add_contact.lead_src_btn {
        width: 125px;
    }

    .MarketingModule-container-parent-class .campaign_email_list_header .add_contact.btn-crt {
        width: 156px !important;
    }

    .MarketingModule-container-parent-class .campaign_email_selection_section {
        gap: 4px;
    }
}

@media screen and (min-width: 1470px){
    .lead_src_btn a {
        font-size: 14px;
    }

    .multi-sender-emails-table-wrapper .meis_ctmw_table td {
        font-size: 16px !important;
    }
}

.MarketingModule-container-parent-class .campaign_phn_no h6, .MarketingModule-container-parent-class .campaign_time_zone h6 {
     font-size: 16px !important;
}

@media screen and (max-width: 1324px) {
    .campaign_phn_no h6, .campaign_time_zone h6 {
        font-size: 16px !important;
    }
}

@media screen and (max-width: 1399px) {
    .number_count_title h6, .campaign_phn_no_time_zone h6 {
        font-size: 16px;
    }
}

