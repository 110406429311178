.wbh_output_preview_wrapper{
    width: 430px;
    padding: 20px 16px 0 16px;
    transition: all 300ms ease-in-out;
}

.wbh_output_preview_wrapper .pre{
    border: 1px solid rgba(148, 157, 178, 0.24);
    page-break-inside: avoid;
    display: block;
    padding: 8px 12px;
    line-height: 20px;
    word-break: break-all;
    word-wrap: break-word;
    background-color: white;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    font-family: Monaco, Menlo, Consolas, "Courier New", monospace;
    font-size: 12px;
    color: #133159;
    overflow-x: auto;
}

.wbh_output_preview_wrapper .pre:not(:last-child) {
    margin: 0 0 10px;
}

.wbh_output_preview_wrapper .pre code {
    padding: 0;
    color: inherit;
    white-space: pre;
    background-color: transparent;
    border: 0;
}

.wbh_output_preview_wrapper.collapsed{
    width: 58px;
    transition: all 300ms ease-in-out;
}

.wbh_output_preview_wrapper.collapsed .pre,
.wbh_output_preview_wrapper.collapsed .wbh_opw_buttons,
.wbh_output_preview_wrapper.collapsed .wbh_opw_test_log{
    display: none;
}

.wbh_opw_http{
    margin-bottom: 10px;
}

.wbh_output_preview_wrapper.collapsed .wbh_opw_header{
    flex-direction: column;
}

.wbh_opw_header {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 12px;
}

.wbh_output_preview-wrapper.collapsed .wbh_opwh_icon{
    transition: all 300ms ease-in-out;
    transform: scaleX(-1);
}

.wbh_opw_header .wbh_opwh_icon{
    cursor: pointer;
}

.wbh_output_preview_wrapper.collapsed .wbh_text_expand{
    writing-mode: vertical-rl;
}