.p_g_u_l_c_d_main_w{
    padding: 10px;
    max-height: 400px;
    width: 300px;
}
.p_g_u_l_c_d_main_w .search__input{
    border: 1px solid #ddd;
    border-radius: 6px;
    padding: 2px 10px;
    margin-bottom: 0 !important;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #546376;
    height: 39px;
}
.p_g_u_l_c_d_main_w .empty__containner{
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    color: #000;
}
.p_g_u_l_c_d_main_w .pype_g_u_l_c_dd{
    width: 100%;
    padding-bottom: 5px !important;
    max-height: 400px;
    /* height: calc(100% - 100px) !important; */
}
.p_g_u_l_c_d_main_w .pype_g_u_l_c_dd .pgwptc__group__each__item-ovr{
    /*width: 100%;*/
    padding: 10px 6px 10px 0px;
    cursor: pointer;
    display: flex;
    align-items: center;
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #546376;
}

.p_g_u_l_c_d_main_w .pype_g_u_l_c_dd .pgwptc__group__each__item-ovr .pgwptc__gei_title {
    margin-left: 10px;
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.custom_menu_option_container .avater__name__sub__default__list{
    background: #ddd;
    padding: 0px 5px;
    border-radius: 15px;
    margin-right: 5px;
    font-size: 12px;
}

.pypepro_global__custom__field__grouped__wraper .custom_menu_option_container .label_class_wrapper{
    text-transform: unset !important;
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 6px 4px;
    align-items: center;
    cursor: pointer;
}
.pypepro_global__custom__field__grouped__wraper .custom_menu_option_container .label_class_wrapper span{
    display: inline-flex;
}
/* .pypepro_global__custom__field__grouped__wraper .custom_menu_option_container .label_class_wrapper .iv2__su__single{
    display: block !important;
    width: 98%;
} */
#pype_g_u_l_c_dd_w_id .p_g_u_l_c_d_main_w.awesome__scroll_bar::-webkit-scrollbar{
    width: 8px;
}

.pypepro_global__custom__field__grouped__wraper .custom_menu_option_container{
    min-width: 250px !important;
    max-width: 290px !important;
}

.pypepro_global__custom__field__grouped__wraper .list___item__iv2{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #546376;
}

.pgwptc__group__each__item-wrp .current_default_flag_chip {
    background-color: #c5dec585;
    color: #f78282;
    font-size: 11px;
    padding: 2px 9px;
    border-radius: 15px;
    margin-left: 6px;
}

.pgwptc__group__each__item-wrp{
    border-bottom: 1px solid #ddd;
    position: relative;
}

.pgwptc__group__each__item-wrp .action-lft{
    position: absolute;
    top: 10px;
    right: 0;
}

.pgwptc__group__each__item-wrp .current_make_default_flag_chip {
    color: #f78282;
    font-size: 11px;
    padding: 0px 7px;
    border-radius: 15px;
    border: 1px solid #f78282;
    margin-left: 6px;
    display: none;
}

.p_g_u_l_c_d_main_w .pype_g_u_l_c_dd .pgwptc__group__each__item-wrp:hover .current_make_default_flag_chip {
    display: block;
    cursor: pointer;
}

.p_g_u_l_c_d_main_w .pype_g_u_l_c_dd .pgwptc__group__each__item-wrp{
    width: 100%;
    display: flex;
    align-items: center;
}