/* =============================================
GLOBAL CSS START
============================================= */

.main_content_container {
	margin: 30px 10px 55px 10px;
	/* max-width: 1602px; */
	/* min-height: 1725px; */
	background: #ffffff;
	border-radius: 10px;
}

body.active__minimal_sidebar .main__content_wrapper .main__content .main_content_container {
	max-width: 1770px;
	/* margin-left: 30px; */
}

.main_content_container .main_content_topbar {
	height: 88px;
	width: 100%;
	padding: 18px 20px 20px 40px;
}

.main_content_container h6 {
	font-family: var(--poppins);
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 30px;
	letter-spacing: 0.03em;
	text-transform: capitalize;
	color: var(--dark_blue);
	margin: 0;
}

.main_content_container {
	font-family: var(--poppins);
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 30px;
	color: var(--gray);
	margin: 0;
}

.main_content_container a {
	font-family: var(--poppins);
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	display: flex;
	align-items: center;
	letter-spacing: 0.01em;
	color: #ffffff;
	padding: 13px 10px;
	/*background: #3c7ef3;*/
	border-radius: 5px;
}

/* ===============================================
GLOBAL CSS END
=============================================== */

/* ===============================================
Manage Templates Page CSS START
=============================================== */

.main_content_container .main_content_topbar h6 {
	margin-top: 12px;
}

.main_content_topbar .new_template_button a svg {
	margin-right: 10px;
}

.main_content_topbar .search_bar {
	width: 218px;
	background: #ffffff;
	border: 1px solid rgba(19, 49, 89, 0.35);
	border-radius: 6px;
	padding: 13px 9px;
	margin: 0 30px;
}

.main_content_topbar .search_bar_quicktemplate_global {
	width: 218px;
	margin: 0 30px;
}

.main_content_topbar .search_bar_quicktemplate_global input {
	height: 3rem;
}

.main_content_topbar .search_bar input {
	font-family: var(--poppins);
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 22px;
	letter-spacing: 0.01em;
	color: rgba(19, 49, 89, 0.5);
	border-bottom: 0px !important;
	height: auto !important;
	margin: 0px !important;
}

.main_content_topbar .search_bar input:focus {
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
}

.main_content_topbar .search_bar input::placeholder {
	font-family: var(--poppins);
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 22px;
	letter-spacing: 0.01em;
	color: rgba(19, 49, 89, 0.5);
}

.main_content_container .input-field {
	margin: 0;
	background: #f6f7fb;
}

.main_content_container .select-wrapper::after {
	position: absolute;
	content: "";
	border-top: 0px;
	border-left: 0px;
	display: block;
	width: 8px;
	height: 8px;
	top: 50%;
	right: 14px;
	margin-top: -3px;
	border-bottom: 2px solid #667993;
	border-right: 2px solid #667993;
	transform: rotate(45deg) translateY(-50%);
	transform-origin: 50% 0;
}

.main_content_container .select-wrapper svg {
	display: none;
}

.main_content_container .input-field .select-wrapper input.select-dropdown {
	width: 197px;
	margin: 0;
	font-family: var(--poppins);
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 22px;
	letter-spacing: 0.01em;
	color: #8896a8;
	border-bottom: 0;
	border: 1px solid #e5e8ef;
	border-radius: 5px;
	height: 22px;
	padding: 13px 0 13px 14px;
}

.main_content_container .input-field ul {
	width: 197px;
	background: #ffffff;
	box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.03);
	border-radius: 10px;
	padding: 18px 0 1px 0;
	top: -51px;
}

.main_content_container .input-field ul li {
	height: auto;
	min-height: auto;
}

.main_content_container .main_content_topbar .input-field ul li:first-child {
	display: none;
}

.main_content_container .input-field ul li span {
	font-family: var(--poppins);
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 24px;
	letter-spacing: 0.01em;
	text-transform: capitalize;
	color: var(--dark_blue);
	padding: 0;
	margin: 0 0 10px 17px;
}

.main_content_divider {
	max-width: 1750px;
	margin-left: 20px;
	height: 2px;
	background: var(--gray_dark);
}

.main_content_description {
	height: auto;
}

.main_content_description .templates_container {
	padding: 34px 20px 40px 20px;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 20px;
}
.main_content_description.templates-container-alt .templates_container {
	display: flex;
	flex-wrap: wrap;
    grid-gap: 0;
}

.main_content_description .templates_container .template {
	height: 507px;
	/* width: 507px; */
	width: auto;
	background: #ffffff;
	box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.05);
	border-radius: 10px;
	position: relative;
	overflow: hidden;
}

body.active__minimal_sidebar .main__content_wrapper .main__content .main_content_description .templates_container .template {
	width: 563px;
}

.main_content_description .templates_container .template::before {
	content: "";
	position: absolute;
	text-align: center;
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 10px;
	line-height: 20px;
	letter-spacing: 0.03em;
	color: #ffffff;
	width: 98.16px;
	height: 19.01px;
	left: -20px;
	top: 18px;
	transform: rotate(-44.23deg);
}

.main_content_description .templates_container .template::before {
	font-size: 16px;
	line-height: 23px;
	width: 160px;
	height: 25px;
	left: -30px;
	top: 35px;
}

.main_content_description .templates_container .template h6 {
	width: calc(100% - 140px);
	text-align: center;
	margin-left: auto !important;
	margin-right: auto !important;
	/* padding-left: 60px; */
}

.main_content_description .templates_container .sms_template.template::before {
	content: "SMS";
	background: #7da017;
}

.main_content_description .templates_container .mms_template.template::before {
	content: "MMS";
	background: #1041bf;
}

.main_content_description .templates_container .email_template.template::before {
	content: "Email";
	background: #4f19e9;
}

.main_content_description .templates_container .video_email_template.template::before {
	content: "Videomail";
	background: #2a0404;
}

.main_content_description .templates_container .voice_template.template::before {
	content: "Voice";
	background: #d819e9;
}

.main_content_description .templates_container .postcard_template.template::before {
	content: "Postcard";
	background: #3ebf10;
}

.main_content_description .templates_container .greeting_card_template.template::before {
	content: "Greetingcard";
	background: #1041bf;
}

.main_content_description .templates_container .letter_template.template::before {
	content: "Letter";
	background: #ff3642;
}

.main_content_description .templates_container .gift_card_template.template::before {
	content: "Gift Card";
	background: #fb26ff;
}

.main_content_description .templates_container .template h6 {
	margin: 20px 0 15px 0;
}

.MarketingModule-container-parent-class .main_content_description .templates_container .template .template_display.quick-reply-display-div {
	/* width: 467px; */
	/* width: 100%; */
	height: calc(100% - 168px);
    margin: 30px;
	/* height: 362px; */
	/* margin: 0 auto; */
	/* background: #ffffff; */
	border: 1px solid rgba(46, 53, 65, 0.1);
	background:#EDF6FA;

	border-radius: 10px;
}

body.active__minimal_sidebar .main__content_wrapper .main__content .main_content_description .templates_container .template .template_display {
	width: 523px;
}

/* SMS Template */

.main_content_description .templates_container .sms_template .template_display {
	padding: 13px 17px;
}

.main_content_description .templates_container .sms_template .template_display p {
	font-family: var(--poppins);
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 21px;
	letter-spacing: 0.01em;
	color: var(--dark_blue);
}

/* MMS Template */

.main_content_description .templates_container .mms_template .template_display>div {
	height: 100%;
}

.main_content_description .templates_container .mms_template .template_display img {
	border-radius: 10px;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

/* Email Template */

.main_content_description .templates_container .email_template .template_display {
	padding: 8.5px 14.5px 8.5px 11.5px;
	padding: 32px 14.5px 8.5px 11.5px;
	padding: 30px;
}

.main_content_description .templates_container .email_template .template_display .regarding_person {
	margin-top: 15px;
}

/* Voice Template */

/* Audio Player */

.main_content_description .templates_container .voice_template .template_display {
	display: flex;
	align-items: center;
}

.main_content_description .templates_container .voice_template .template_display .audio_player {
	/* height: 22px; */
	/* width: 432px; */
	padding: 0 16px 0 17px;
	/* background: #726f6f; */
	/* width: 100%; */
}

.main_content_description .templates_container .voice_template .template_display .audio_player .controls .play_button {
	display: none;
}

.main_content_description .templates_container .voice_template .template_display .audio_player .controls .play_pause_button {
	height: 20px;
}

.main_content_description .templates_container .voice_template .template_display .audio_player .controls .timer {
	margin: 0 26px 0 20px;
}

.main_content_description .templates_container .voice_template .template_display .audio_player .controls .timer p {
	font-size: 0px;
	height: 21px;
}

.main_content_description .templates_container .voice_template .template_display .audio_player .controls .timer p span {
	font-family: var(--poppins);
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 21px;
	letter-spacing: 0.01em;
	color: var(--gray);
}

.main_content_description .templates_container .voice_template .template_display .audio_player .controls .timeline {
	height: 20px;
	width: 176px;
}

.main_content_description .templates_container .voice_template .template_display .audio_player .controls .timeline .full_timeline {
	height: 10px;
	margin-top: 5px;
	background: rgba(60, 126, 243, 0.1);
	border-radius: 5px;
}

.main_content_description .templates_container .voice_template .template_display .audio_player .controls .timeline .playing_timeline {
	width: 73px;
	height: 10px;
	background: #3c7ef3;
	border-radius: 5px;
	position: relative;
}

.main_content_description .templates_container .voice_template .template_display .audio_player .controls .timeline .playing_timeline::after {
	content: "";
	position: absolute;
	top: -5px;
	right: -10px;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background: #3c7ef3;
	border: 1px solid #ffffff;
	box-shadow: 0px 4px 4px rgba(98, 54, 255, 0.2);
	z-index: 999;
}

.main_content_description .templates_container .voice_template .template_display .audio_player .controls .volume {
	height: 20px;
	width: 88px;
	margin: 0 12px 0 20px;
}

.main_content_description .templates_container .voice_template .template_display .audio_player .controls .volume .volume_bar {
	height: 10px;
	width: 60px;
	margin-left: 5.8px;
}

.main_content_description .templates_container .voice_template .template_display .audio_player .controls .volume .full_volume_bar {
	height: 5px;
	width: 60px;
	margin-top: 2px;
	background: rgba(60, 126, 243, 0.1);
	border-radius: 5px;
}

.main_content_description .templates_container .voice_template .template_display .audio_player .controls .volume .playing_volume_bar {
	width: 22px;
	height: 5px;
	background: #3c7ef3;
	border-radius: 5px;
	position: relative;
}

.main_content_description .templates_container .voice_template .template_display .audio_player .controls .volume .playing_volume_bar::after {
	content: "";
	position: absolute;
	top: -2.5px;
	right: -5px;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background: #3c7ef3;
	border: 1px solid #ffffff;
	box-shadow: 0px 4px 4px rgba(98, 54, 255, 0.2);
	z-index: 999;
}

/* Postcard Template */

.main_content_description .templates_container .postcard_template .template_display {
	text-align: center;
}

.main_content_description .templates_container .postcard_template .template_display iframe {
	width: 100%;
	height: 100%;
	border: none;
}

/* Greeting Card Template */

.main_content_description .templates_container .greeting_card_template .template_display {
	text-align: center;
}

.main_content_description .templates_container .greeting_card_template .template_display iframe {
	width: 100%;
	height: 100%;
	border: none;
}

/* Letter Template */

.main_content_description .templates_container .letter_template .template_display iframe {
	width: 100%;
	height: 100%;
	border: none;
}

/* Gift Card Card Template */

.main_content_description .templates_container .gift_card_template .template_display {
	text-align: center;
}

.main_content_description .templates_container .gift_card_template .template_display iframe {
	width: 100%;
	height: 80%;
	border: none;
}

.main_content_description .templates_container .template .template_buttons {
	margin-top: 20px;
}

.main_content_description .templates_container .template .template_buttons a {
	padding: 7px 12px;
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 24px;
	display: flex;
	align-items: center;
	letter-spacing: 0.01em;
	color: #ffffff;
}

.main_content_description .templates_container .template .template_buttons svg {
	margin-right: 10px;
}

.main_content_description .templates_container .template .template_buttons .view_button {}

.main_content_description .templates_container .template .template_buttons .edit_button {
	margin: 0 10px;
}

.main_content_description .templates_container .template .template_buttons .delete_button {
	background: #ff5858;
}

.react-confirm-alert-body h1 {
	font-size: 2rem;
}

.validate.emailSubjectTextField {
	width: 100% !important;
}

@media (max-width: 1366px) {
	.main_content_container {
		/* max-width: 1048px; */
		max-width: 100%;
		min-height: 1249px;
		padding-left: 0;
		padding-right: 0;
	}
	body.active__minimal_sidebar .main__content_wrapper .main__content .main_content_container {
		max-width: 1218px;
	}
	.main_content_container h6 {
		font-size: 16px;
	}
	.main_content_container {
		font-size: 12px;
		line-height: 20px;
	}
	.main_content_container .main_content_topbar {
		padding: 18px 20px 20px 20px;
	}
	.main_content_description .templates_container {
		padding: 23px 20px 28px 32px;
		grid-gap: 33px;
	}
	.main_content_description.templates-container-alt .templates_container {
		padding: 15px;
    	grid-gap: 0;
	}
	.main_content_description .templates_container .template {
		height: 354px;
		/* width: 310px; */
		width: auto !important;
	}
	body.active__minimal_sidebar .main__content_wrapper .main__content .main_content_description .templates_container .template {
		width: 366px;
	}
	.main_content_description .templates_container .template .template_display {
		/* width: 271px; */
		width: 100%;
		height: 217px;
	}
	body.active__minimal_sidebar .main__content_wrapper .main__content .main_content_description .templates_container .template .template_display {
		width: 326px;
	}
	.main_content_description .templates_container .sms_template .template_display {
		padding: 40px 21px 16px 17px;
	}
	.main_content_description .templates_container .sms_template .template_display p {
		font-size: 12px;
		line-height: 18px;
	}
	.main_content_description .templates_container .email_template .template_display {
		padding: 32px 0 5px 12px;
	}
	.main_content_description .templates_container .email_template .template_display .regarding_person {
		margin-top: 2px;
	}
	body.active__minimal_sidebar .main__content_wrapper .main__content .main_content_description .templates_container .email_template .template_display .regarding_person {
		margin-top: 22px;
	}
	.main_content_description .templates_container .email_template .template_display .regarding_person p {
		line-height: 18px;
	}
	.main_content_description .templates_container .voice_template .template_display .audio_player {
		width: 271px;
		padding: 0 5px 0 10px;
	}
	body.active__minimal_sidebar .main__content_wrapper .main__content .main_content_description .templates_container .voice_template .template_display .audio_player {
		width: 366px;
		padding: 0 20px 0 20px;
	}
	.main_content_description .templates_container .voice_template .template_display .audio_player .controls .timer {
		margin: 0 5px 0 5px;
	}
	body.active__minimal_sidebar .main__content_wrapper .main__content .main_content_description .templates_container .voice_template .template_display .audio_player .controls .timer {
		margin: 0 10px 0 10px;
	}
	.main_content_description .templates_container .voice_template .template_display .audio_player .controls .timeline {
		width: 80px;
	}
	.main_content_description .templates_container .voice_template .template_display .audio_player .controls .timeline .playing_timeline {
		width: 33px;
	}
	.main_content_description .templates_container .voice_template .template_display .audio_player .controls .volume {
		width: 58px;
		margin: 0 5px 0 5px;
	}
	body.active__minimal_sidebar .main__content_wrapper .main__content .main_content_description .templates_container .voice_template .template_display .audio_player .controls .volume {
		margin: 0 10px 0 10px;
	}
	.main_content_description .templates_container .voice_template .template_display .audio_player .controls .volume .volume_bar {
		width: 35px;
	}
	.main_content_description .templates_container .voice_template .template_display .audio_player .controls .volume .full_volume_bar {
		width: 35px;
	}
	.main_content_description .templates_container .voice_template .template_display .audio_player .controls .volume .playing_volume_bar {
		width: 12px;
	}
	/* Postcard Template */
	.main_content_description .templates_container .postcard_template .template_display img {
		width: 100%;
		height: 100%;
	}
	/* Greeting Card Template */
	.main_content_description .templates_container .greeting_card_template .template_display img {
		width: 100%;
		height: 100%;
	}
	/* Gift Card Card Template */
	.main_content_description .templates_container .gift_card_template .template_display img {
		width: 100%;
		height: 100%;
	}
	.main_content_description .templates_container .template .template_buttons {
		margin-top: 15px;
	}
	.main_content_description .templates_container .template .template_buttons a {
		padding: 6px 10px;
		font-size: 12px;
		line-height: 24px;
	}
	.main_content_description .templates_container .template .template_buttons span {
		margin-left: 6px;
	}
	.main_content_description .templates_container .template .template_buttons svg {
		transform: scale(0.74);
		margin-right: 0px;
	}
}

@media (max-width: 768px) {
	body.active__minimal_sidebar .main__content_wrapper .main__content .main_content_container {
		max-width: 640px;
	}
	body.active__minimal_sidebar .main__content_wrapper .main__content .main_content_container {
		margin: 20px 0 0 0;
		height: 931px;
	}
	.main_content_container .main_content_topbar {
		padding: 20px 10px 20px 10px;
	}
	.main_content_container h6 {
		font-size: 12px !important;
		line-height: 20px !important;
		margin-top: 0px !important;
	}
	.main_content_topbar .search_bar {
		width: 139px;
		padding: 4px 13px;
		margin: 0 10px;
	}
	.main_content_topbar .search_bar input {
		font-size: 10px;
		line-height: 20px;
		color: #99a6b9;
	}
	.main_content_topbar .search_bar input::placeholder {
		font-size: 10px;
		line-height: 20px;
		color: #99a6b9;
	}
	.main_content_topbar .search_bar svg path {
		fill: rgba(19, 49, 89, 0.5);
	}
	.main_content_container .select-wrapper::after {
		right: 5px;
	}
	.main_content_container .input-field .select-wrapper input.select-dropdown {
		width: 84px;
		font-size: 10px;
		line-height: 20px;
		height: 20px;
		padding: 4px 0 4px 5px;
	}
	.main_content_container .input-field ul {
		width: 113px !important;
		top: -31px;
	}
	.main_content_container .input-field ul li span {
		font-size: 10px;
		margin: 0 0 10px 17px;
	}
	.main_content_container {
		font-size: 8px;
		line-height: 14px;
	}
	.main_content_container a {
		font-size: 10px;
		line-height: 24px;
		padding: 3px 10px;
	}
	.main_content_description .templates_container {
		padding: 13px 10px 25px 13px;
		grid-gap: 8px;
	}
	body.active__minimal_sidebar .main__content_wrapper .main__content .main_content_description .templates_container .template {
		height: 258px;
		width: 200px;
	}
	body.active__minimal_sidebar .main__content_wrapper .main__content .main_content_description .templates_container .template .template_display {
		width: 180px;
		height: 150px;
	}
	.main_content_description .templates_container .template h6 {
		margin: 23px 0 13px 0 !important;
	}
	.main_content_description .templates_container .sms_template .template_display {
		padding: 9px 22px 9px 9px;
		overflow: hidden;
	}
	.main_content_description .templates_container .sms_template .template_display p {
		font-size: 8px;
		line-height: 14px;
	}
	.main_content_description .templates_container .email_template .template_display {
		padding: 9px 0 0 12px;
	}
	.main_content_description .templates_container .email_template .template_display .regarding_person {
		margin-top: 2px;
	}
	body.active__minimal_sidebar .main__content_wrapper .main__content .main_content_description .templates_container .email_template .template_display .regarding_person {
		margin-top: 3px;
	}
	.main_content_description .templates_container .email_template .template_display .regarding_person p {
		line-height: 14px;
	}
	.main_content_description .templates_container .email_template .template_display {
		padding: 9px 38px 10px 9px;
		overflow: hidden;
	}
	.main_content_description .templates_container .email_template .template_display .regarding_person p:nth-child(3), .main_content_description .templates_container .email_template .template_display .regarding_person p:nth-child(4) {
		display: none;
	}
	body.active__minimal_sidebar .main__content_wrapper .main__content .main_content_description .templates_container .voice_template .template_display .audio_player {
		width: 180px;
		padding: 0 5px 0 5px;
	}
	.main_content_description .templates_container .voice_template .template_display .audio_player .controls .timer {
		margin: 0 5px 0 5px;
	}
	body.active__minimal_sidebar .main__content_wrapper .main__content .main_content_description .templates_container .voice_template .template_display .audio_player .controls .timer {
		margin: 0 5px 0 5px;
	}
	.main_content_description .templates_container .voice_template .template_display .audio_player .controls .timer p span {
		font-size: 8px;
		line-height: 21px;
	}
	.main_content_description .templates_container .voice_template .template_display .audio_player .controls .timeline {
		width: 40px;
	}
	.main_content_description .templates_container .voice_template .template_display .audio_player .controls .timeline .playing_timeline {
		width: 13px;
	}
	.main_content_description .templates_container .voice_template .template_display .audio_player .controls .volume {
		width: 38px;
		margin: 0 5px 0 5px;
	}
	body.active__minimal_sidebar .main__content_wrapper .main__content .main_content_description .templates_container .voice_template .template_display .audio_player .controls .volume {
		margin: 0 5px 0 5px;
	}
	.main_content_description .templates_container .voice_template .template_display .audio_player .controls .volume .volume_bar {
		width: 15px;
	}
	.main_content_description .templates_container .voice_template .template_display .audio_player .controls .volume .full_volume_bar {
		width: 8px;
	}
	.main_content_description .templates_container .voice_template .template_display .audio_player .controls .volume .playing_volume_bar {
		width: 12px;
	}
	/* Postcard Template */
	.main_content_description .templates_container .postcard_template .template_display img {
		width: 100%;
		height: 100%;
	}
	/* Greeting Card Template */
	.main_content_description .templates_container .greeting_card_template .template_display img {
		width: 100%;
		height: 100%;
	}
	/* Gift Card Card Template */
	.main_content_description .templates_container .gift_card_template .template_display img {
		width: 100%;
		height: 100%;
	}
	.main_content_description .templates_container .template .template_buttons {
		margin-top: 10px;
	}
	.main_content_description .templates_container .template .template_buttons .edit_button {
		margin: 0 5px;
	}
	.main_content_description .templates_container .template .template_buttons a {
		padding: 1px 6px 1px 0;
		font-size: 8px;
		line-height: 14px;
	}
	.main_content_description .templates_container .template .template_buttons span {
		margin-left: 0px;
	}
	.main_content_description .templates_container .template .template_buttons svg {
		transform: scale(0.6);
		margin-right: 0px;
	}
}

/* ===============================================
Manage Templates Page CSS END
=============================================== */

/* ===============================================
Add Templates Page CSS START
=============================================== */

.main_content_container.add_template_page {
	height: 778px;
}

.main_content_container.add_template_page .main_content_topbar .back_button a {
	padding: 13px 20px;
}

.main_content_container.add_template_page .main_content_topbar .back_button span {
	margin-left: 10px;
}

.main_content_container.add_template_page .main_content_description {
	padding: 20px 20px 27px 20px;
	display: flex;
}

.main_content_container.add_template_page .main_content_description .add_template_content {
	width: 786px;
	height: 641px;
	background: #f6f7fb;
	border: 1px solid #e5e8ef;
	border-radius: 10px;
	margin-right: 20px;
	padding: 19px;
}

.main_content_container.add_template_page .main_content_description .add_template_display {
	width: 756px;
	height: 641px;
	background: #f6f7fb;
	border: 1px solid #e5e8ef;
	border-radius: 10px;
	position: relative;
}

body.active__minimal_sidebar .main__content_wrapper .main__content .main_content_container.add_template_page .main_content_description .add_template_content {
	width: 855px;
}

body.active__minimal_sidebar .main__content_wrapper .main__content .main_content_container.add_template_page .main_content_description .add_template_display {
	width: 855px;
}

.main_content_container.add_template_page .main_content_description .add_template_content .input-field {
	margin: 0;
}

.main_content_container.add_template_page .main_content_description .add_template_content h6 {
	font-family: var(--poppins);
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 22px;
	letter-spacing: 0.01em;
	color: var(--dark_blue);
	margin-bottom: 10px;
}

.main_content_container.add_template_page .main_content_description .add_template_content .input-field input {
	margin: 0;
	background: #ffffff;
	border: 1px solid #e5e8ef;
	border-radius: 5px;
	padding: 10px 0 10px 11px;
	font-family: var(--poppins);
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 22px;
	letter-spacing: 0.01em;
	color: #8896a8;
	outline: none;
	height: auto;
	/* width: 733px; */
	width: 100%;
	box-sizing: border-box;
}

.main_content_container input {
	box-shadow: unset !important;
}

/* body.active__minimal_sidebar .main__content_wrapper .main__content .main_content_container.add_template_page .main_content_description .add_template_content .input-field input{
    width: 802px;
} */

.main_content_container.add_template_page .main_content_description .add_template_content .input-field input:focus {
	-webkit-box-shadow: none;
	box-shadow: none;
}

.main_content_container.add_template_page .main_content_description .add_template_content .row {
	margin: 0;
}

.main_content_container.add_template_page .main_content_description .add_template_content .tabs {
	margin: 20px 0;
	width: 500px;
	height: 40px;
	display: flex;
	background: var(--gray_light);
}

.main_content_container.add_template_page .main_content_description .add_template_content .tabs .tab {
	height: 40px;
}

.main_content_container.add_template_page .main_content_description .add_template_content .tabs .indicator {
	height: 0px;
}

.main_content_container.add_template_page .main_content_description .add_template_content .tabs .tab a {
	display: flex;
	align-items: center;
	padding: 0 11px;
	height: 40px;
	border: 1px solid rgba(60, 126, 243, 0.1);
	text-transform: capitalize;
	border-radius: 0;
	background-color: transparent;
}

.main_content_container.add_template_page .main_content_description .add_template_content .tabs .tab:first-child a {
	border-radius: 10px 0px 0px 10px;
}

.main_content_container.add_template_page .main_content_description .add_template_content .tabs .tab:nth-child(5) a {
	border-radius: 0px 10px 10px 0px;
}

.main_content_container.add_template_page .main_content_description .add_template_content .tabs .tab a:focus, .main_content_container.add_template_page .main_content_description .add_template_content .tabs .tab a:hover {}

.main_content_container.add_template_page .main_content_description .add_template_content .tabs .tab span {
	font-family: var(--poppins);
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 18px;
	letter-spacing: 0.01em;
	color: #133159;
	margin-left: 12px;
}

.main_content_container.add_template_page .main_content_description .add_template_content .tabs .tab .active svg, .main_content_container.add_template_page .main_content_description .add_template_content .tabs .tab .active svg path, .main_content_container.add_template_page .main_content_description .add_template_content .tabs .tab .active svg ellipse {
	fill: #3c7ef3 !important;
}

.main_content_container.add_template_page .main_content_description .add_template_content .tabs .tab .active span {
	color: #3c7ef3;
}

/* Tiny MCE Tex Editor CSS */

.tox-tinymce {
	border: none !important;
}

.tox .tox-toolbar, .tox .tox-toolbar__overflow, .tox .tox-toolbar__primary {
	background: none !important;
	background-color: #f6f7fb !important;
}

.tox:not([dir="rtl"]) .tox-toolbar__group {
	border-right: 1px solid #e5e8ef !important;
	border-top: 1px solid #e5e8ef !important;
	background: #ffffff;
}

.tox:not([dir="rtl"]) .tox-toolbar__group:first-of-type {
	border-left: 1px solid #e5e8ef !important;
	border-radius: 10px 0px 0px 0px;
}

.tox:not([dir="rtl"]) .tox-toolbar__group:last-of-type {
	border-radius: 0px 10px 0px 0px;
}

.tox .tox-tbtn {
	width: 20.88px !important;
}

body.active__minimal_sidebar .main__content_wrapper .main__content .main_content_container.add_template_page .tox .tox-tbtn--select {
	width: 121px !important;
}

.tox .tox-tbtn--select {
	width: 121px !important;
}

.tox .tox-tbtn--select span {
	font-family: var(--poppins);
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.01em;
	color: #546376;
}

.tox .tox-tbtn svg {
	transform: scale(0.7);
}

.tox .tox-tbtn:active {
	background: #ffffff !important;
	color: #3c7ef3 !important;
}

.tox .tox-tbtn:active svg {
	fill: #3c7ef3 !important;
}

.tox .tox-tbtn:hover {
	background: #ffffff !important;
	color: #3c7ef3 !important;
}

.tox .tox-tbtn--enabled, .tox .tox-tbtn--enabled:hover {
	background: #ffffff !important;
	color: #3c7ef3 !important;
}

.tox .tox-tbtn--enabled svg {
	fill: #3c7ef3 !important;
}

.tox .tox-edit-area {
	border-radius: 0 0 10px 10px;
}

.mce-content-body {
	font-size: 4px !important;
}

.tox-tbtn__select-chevron svg {
	display: none !important;
}

.tox-tbtn--select {
	position: relative !important;
}

.tox-tbtn--select::after {
	position: absolute;
	content: "";
	display: block;
	top: 14px;
	right: 4px;
	width: 0;
	height: 0;
	border: 5px solid transparent;
	border-color: #546376 transparent transparent transparent;
}

.tox-split-button__chevron svg {
	display: none !important;
}

.tox-split-button {
	position: relative !important;
}

.tox-split-button::after {
	position: absolute;
	content: "";
	display: block;
	top: 14px;
	right: 4px;
	width: 0;
	height: 0;
	border: 5px solid transparent;
	border-color: #546376 transparent transparent transparent;
}

.main_content_container.add_template_page .main_content_description .add_template_content .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
	color: rgba(165, 165, 165, 1) !important;
}

/* Smartphone Css */

.main_content_container.add_template_page .main_content_description .add_template_display .smartphone_image {
	/* margin: 38px auto 38px auto; */
	margin: 0px auto 38px auto;
	width: 409px;
	height: 565px;
	position: relative;
}

.main_content_container.add_template_page .main_content_description .add_template_display .smartphone_image img {
	width: 100%;
}

.main_content_container.add_template_page .main_content_description .add_template_display .smartphone_display {
	position: absolute;
	width: 360px;
	/* height: 430px; */
	height: auto;
	max-height: 400px;
	background-color: #ffffff;
	top: 68px;
	left: 27px;
	border-radius: 6px;
	overflow: hidden;
}

.main_content_container.add_template_page .main_content_description .add_template_display .smartphone_display h6 {
	padding: 10px;
}

.main_content_container.add_template_page .main_content_description .add_template_display .smartphone_display {}

.main_content_container.add_template_page .main_content_description .add_template_display .smartphone_display .display_number {
	background: #5a82c2;
}

.main_content_container.add_template_page .main_content_description .add_template_display .smartphone_display .display_number h6 {
	color: #ffffff;
	text-align: center;
	font-size: 16px;
}

.main_content_container.add_template_page .main_content_description .add_template_display .smartphone_display .message_box {
	width: 100%;
	height: auto;
	min-height: 200px;
	padding: 10px;
}

.main_content_container.add_template_page .main_content_description .add_template_display .smartphone_display .message_box .display_message {
	margin-left: 40px;
}

.main_content_container.add_template_page .main_content_description .add_template_display .smartphone_display .message_box .display_message .message_time p {
	font-size: 12px;
	line-height: 20px;
	color: #828282;
	margin-bottom: 6px;
}

/* .main_content_container.add_template_page .main_content_description .add_template_display .smartphone_display .message_box .display_message .display_message_body p */

.display_message_body {
	font-size: 14px;
	background-color: #dddddd;
	padding: 10px;
	line-height: 18px;
	/* padding-bottom: 22px; */
	padding-bottom: 10px;
	border-radius: 10px;
	color: #666666;
	word-break: break-all;
}

.messageSubject {
	font-size: 16px;
	text-transform: capitalize;
	margin-bottom: 8px;
	color: #5a82c2;
}

.global__message_img, .display_message_body_img {
	width: 200px;
	height: auto;
	object-fit: cover;
	margin-top: 10px;
	border-radius: 6px;
}

.remove__icon {
	all: unset;
	border: 2px solid #d00 !important;
	padding: 6px !important;
	width: 40px !important;
	height: 40px !important;
	line-height: 30 !important;
	border-radius: 50% !important;
	text-align: center;
	position: absolute !important;
	min-width: unset !important;
	color: #d00 !important;
	right: -14px;
	top: 0px;
	background: #fff !important;
	box-shadow: 0 0 0 5px #fff;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline, .main_content_description .MuiOutlinedInput-notchedOutline, .main_content_description .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
	border-color: rgba(0, 0, 0, 0.23) !important;
}

.remove__icon span {
	padding: 0 !important;
}

.display_message_body.alt {
	height: auto;
	max-height: 400px;
}

/* Upper tab buttons */

.main_content_container {
	transition: all 0.3s ease-in-out;
}

.main_content_container.add_template_page .main_content_description .add_template_content .upper_tab_buttons {
	margin-bottom: 10px;
}

.main_content_container.add_template_page .main_content_description .add_template_content .upper_tab_buttons .input-field input {
	background-color: transparent;
	width: 135px;
	padding: 8px 0 8px 31px;
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	letter-spacing: 0.01em;
	color: #133159;
	border-radius: 10px;
}

body.active__minimal_sidebar .main__content_wrapper .main__content .main_content_container.add_template_page .main_content_description .add_template_content .upper_tab_buttons .input-field input {
	width: 135px;
}

.main_content_container.add_template_page .main_content_description .add_template_content .upper_tab_buttons .input-field .select-wrapper::after {
	right: 30px;
}

.main_content_container.add_template_page .main_content_description .add_template_content .upper_tab_buttons .select_email input {
	padding: 5px 0 5px 8px;
	width: 252px;
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 28px;
	color: #546376;
}

body.active__minimal_sidebar .main__content_wrapper .main__content .main_content_container.add_template_page .main_content_description .add_template_content .upper_tab_buttons .select_email input {
	width: 252px;
}

.main_content_container.add_template_page .main_content_description .add_template_content .upper_tab_buttons .select_email .select-wrapper::after {
	right: 13px;
}

.main_content_container.add_template_page .main_content_description .add_template_content .upper_tab_buttons .subject_form input {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 28px;
	color: #546376;
	padding: 5px 0 5px 8px;
	width: 287px;
	margin: 0 9px;
}

body.active__minimal_sidebar .main__content_wrapper .main__content .main_content_container.add_template_page .main_content_description .add_template_content .upper_tab_buttons .subject_form input {
	width: 287px;
}

.main_content_container.add_template_page .main_content_description .add_template_content .upper_tab_buttons .subject_form input::placeholder {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 28px;
	color: #546376;
}

.main_content_container.add_template_page .main_content_description .add_template_content .upper_tab_buttons .add_cc_bcc_button {
	margin-top: 10px;
}

.main_content_container.add_template_page .main_content_description .add_template_content .upper_tab_buttons .add_cc_bcc_button a {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 24px;
	color: #3c7ef3;
	background: rgba(60, 126, 243, 0.1);
	border-radius: 5px;
	padding: 3px 8px;
}

.main_content_container.add_template_page .main_content_description .add_template_content .upper_tab_buttons .add_cc_bcc_button a:hover, .main_content_container.add_template_page .main_content_description .add_template_content .upper_tab_buttons .add_cc_bcc_button a:active {
	color: #ffffff;
	background: #3c7ef3;
}

.main_content_container.add_template_page .main_content_description .add_template_content .upper_tab_buttons .add_cc_bcc_button .bcc_button {
	margin-left: 10px;
}

/* Under tab buttons */

.main_content_container.add_template_page .main_content_description .add_template_content .under_tab_buttons {
	margin-top: 10px;
	margin-bottom: 10px;
}

.main_content_container.add_template_page .main_content_description .add_template_content .under_tab_buttons .input-field input {
	background-color: transparent;
	width: 135px;
	padding: 8px 0 8px 31px;
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	letter-spacing: 0.01em;
	color: #133159;
	border-radius: 10px;
}

body.active__minimal_sidebar .main__content_wrapper .main__content .main_content_container.add_template_page .main_content_description .add_template_content .under_tab_buttons .input-field input {
	width: 135px;
}

.main_content_container.add_template_page .main_content_description .add_template_content .under_tab_buttons .input-field .select-wrapper::after {
	right: 30px;
}

.main_content_container.add_template_page .main_content_description .add_template_content .under_tab_buttons .upload_bitton {
	margin-left: 10px;
}

.main_content_container.add_template_page .main_content_description .add_template_content .under_tab_buttons .upload_bitton .btn {
	height: 40px;
	border: 1px solid rgba(60, 126, 243, 0.1);
	border-radius: 10px;
	background-color: transparent;
	box-shadow: none;
	text-transform: capitalize;
	border-radius: 10px;
	padding: 0 12px 0 13px;
}

.main_content_container.add_template_page .main_content_description .add_template_content .under_tab_buttons .upload_bitton.alt .btn {
	height: 50px;
	border-radius: 4px;
	border-color: rgba(0, 0, 0, 0.23);
}

.main_content_container.add_template_page .main_content_description .add_template_content .under_tab_buttons .upload_bitton .btn span {
	margin-left: 6px;
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 21px;
	letter-spacing: 0.01em;
	color: #133159;
}

.main_content_container.add_template_page .main_content_description .add_template_content .under_tab_buttons .upload_bitton .btn input {
	width: 197px;
	padding: 0;
}

.main_content_container.add_template_page .main_content_description .add_template_content .under_tab_buttons .save_template_button a {
	padding: 12px 15px;
}

.main_content_container.add_template_page .main_content_description .add_template_content .under_tab_buttons .save_template_button a span {
	margin-left: 10px;
}

/* Video Email Tab Content */

.main_content_container.add_template_page .main_content_description .add_template_content .video_email_container, .main_content_container.add_template_page .main_content_description .add_template_content .voice_email_container {
	/* width: 746px; */
	width: 100%;
	/* height: 180px; */
	height: auto;
	background: rgba(60, 126, 243, 0.1);
	border-radius: 10px;
	margin-bottom: 20px;
	padding: 10px;
}

/* 
body.active__minimal_sidebar .main__content_wrapper .main__content .main_content_container.add_template_page .main_content_description .add_template_content .video_email_container, body.active__minimal_sidebar .main__content_wrapper .main__content .main_content_container.add_template_page .main_content_description .add_template_content .voice_email_container {
    width: 815px;
} */

.main_content_container.add_template_page .main_content_description .add_template_content .video_email_container p, .main_content_container.add_template_page .main_content_description .add_template_content .voice_email_container p {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 21px;
	letter-spacing: 0.01em;
	color: #133159;
}

.main_content_container.add_template_page .main_content_description .add_template_content .video_email_container .record_upload_box {
	width: 299px;
}

.main_content_container.add_template_page .main_content_description .add_template_content .video_email_container .record_upload_box .record_upload_buttons {
	margin-left: 25px;
	margin-top: 19px;
}

.main_content_container.add_template_page .main_content_description .add_template_content .video_email_container .record_upload_box .record_upload_buttons a, .main_content_container.add_template_page .main_content_description .add_template_content .voice_email_container .record_stop_upload_box .record_stop_upload_buttons a {
	background: transparent;
	display: block;
	text-align: center;
	padding: 0;
}

.main_content_container.add_template_page .main_content_description .add_template_content .video_email_container .record_upload_box .record_upload_buttons a p, .main_content_container.add_template_page .main_content_description .add_template_content .voice_email_container .record_stop_upload_box .record_stop_upload_buttons a p {
	font-family: Poppins;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 26px;
	letter-spacing: 0.01em;
	color: #546376;
}

.main_content_container.add_template_page .main_content_description .add_template_content .video_email_container .record_upload_box .record_upload_buttons .record_button {
	margin-right: 28px;
}

.main_content_container.add_template_page .main_content_description .add_template_content .video_email_container .create_video_box {
	width: 353px;
	text-align: center;
}

.main_content_container.add_template_page .main_content_description .add_template_content .video_email_container .create_video_box .big_record_icon a {
	width: 353px;
	height: 68px;
	margin-top: 19px;
	text-align: center;
	background: #ffffff;
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.main_content_container.add_template_page .main_content_description .add_template_content .video_email_container .warning_box, .main_content_container.add_template_page .main_content_description .add_template_content .voice_email_container .warning_box {
	margin-top: 18px;
	display: flex;
	align-items: center;
}

.main_content_container.add_template_page .main_content_description .add_template_content .video_email_container .warning_box p, .main_content_container.add_template_page .main_content_description .add_template_content .voice_email_container .warning_box p {
	font-size: 12px;
	line-height: 18px;
	margin-left: 8px;
	color: #ff6b6b;
}

/* Voice Email Tab Content */

/* .main_content_container.add_template_page .main_content_description .add_template_content .voice_email_container .record_stop_upload_box{
    width: 254px;
} */

.main_content_container.add_template_page .main_content_description .add_template_content .voice_email_container .record_stop_upload_box .record_stop_upload_buttons {
	margin-left: 20px;
	margin-top: 20px;
}

.main_content_container.add_template_page .main_content_description .add_template_content .voice_email_container .record_stop_upload_box .record_stop_upload_buttons .stop_button {
	margin: 0 35px;
}

/* Audio Player */

.main_content_container.add_template_page .main_content_description .add_template_content .voice_email_container .audio_player {
	height: 22px;
	width: 432px;
	background: transparent;
	width: 100%;
	margin-left: 43px;
	margin-top: 68px;
}

.main_content_container.add_template_page .main_content_description .add_template_content .voice_email_container .audio_player .controls .play_button {
	display: none;
}

.main_content_container.add_template_page .main_content_description .add_template_content .voice_email_container .audio_player .controls .play_pause_button {
	height: 20px;
}

.main_content_container.add_template_page .main_content_description .add_template_content .voice_email_container .audio_player .controls .timer {
	margin: 0 26px 0 20px;
}

.main_content_container.add_template_page .main_content_description .add_template_content .voice_email_container .audio_player .controls .timer p {
	font-size: 0px;
	height: 21px;
}

.main_content_container.add_template_page .main_content_description .add_template_content .voice_email_container .audio_player .controls .timer p span {
	font-family: var(--poppins);
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 21px;
	letter-spacing: 0.01em;
	color: var(--gray);
}

.main_content_container.add_template_page .main_content_description .add_template_content .voice_email_container .audio_player .controls .timeline {
	height: 20px;
	width: 176px;
}

.main_content_container.add_template_page .main_content_description .add_template_content .voice_email_container .audio_player .controls .timeline .full_timeline {
	height: 10px;
	margin-top: 5px;
	background: rgba(60, 126, 243, 0.1);
	border-radius: 5px;
}

.main_content_container.add_template_page .main_content_description .add_template_content .voice_email_container .audio_player .controls .timeline .playing_timeline {
	width: 73px;
	height: 10px;
	background: #3c7ef3;
	border-radius: 5px;
	position: relative;
}

.main_content_container.add_template_page .main_content_description .add_template_content .voice_email_container .audio_player .controls .timeline .playing_timeline::after {
	content: "";
	position: absolute;
	top: -5px;
	right: -10px;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background: #3c7ef3;
	border: 1px solid #ffffff;
	box-shadow: 0px 4px 4px rgba(98, 54, 255, 0.2);
	z-index: 999;
}

.main_content_container.add_template_page .main_content_description .add_template_content .voice_email_container .audio_player .controls .volume {
	height: 20px;
	width: 88px;
	margin: 0 12px 0 20px;
}

.main_content_container.add_template_page .main_content_description .add_template_content .voice_email_container .audio_player .controls .volume .volume_bar {
	height: 10px;
	width: 60px;
	margin-left: 5.8px;
}

.main_content_container.add_template_page .main_content_description .add_template_content .voice_email_container .audio_player .controls .volume .full_volume_bar {
	height: 5px;
	width: 60px;
	margin-top: 2px;
	background: rgba(60, 126, 243, 0.1);
	border-radius: 5px;
}

.main_content_container.add_template_page .main_content_description .add_template_content .voice_email_container .audio_player .controls .volume .playing_volume_bar {
	width: 22px;
	height: 5px;
	background: #3c7ef3;
	border-radius: 5px;
	position: relative;
}

.main_content_container.add_template_page .main_content_description .add_template_content .voice_email_container .audio_player .controls .volume .playing_volume_bar::after {
	content: "";
	position: absolute;
	top: -2.5px;
	right: -5px;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background: #3c7ef3;
	border: 1px solid #ffffff;
	box-shadow: 0px 4px 4px rgba(98, 54, 255, 0.2);
	z-index: 999;
}

/* Direct Mail Tab Content Css */

.main_content_container.add_template_page .main_content_description .add_template_content .direct_mail_container {
	/* width: 746px; */
	width: 100%;
	height: 115px;
	background: rgba(60, 126, 243, 0.1);
	border-radius: 10px;
	padding: 20px 18px;
}

.main_content_container.add_template_page .main_content_description .add_template_content .direct_mail_container p {
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	letter-spacing: 0.01em;
	color: #333333;
	margin-bottom: 11px;
}

.main_content_container.add_template_page .main_content_description .add_template_content .direct_mail_container .pick_card_buttons a {
	width: 170px;
	background: rgba(60, 126, 243, 0.1);
	border-radius: 6px;
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 22px;
	letter-spacing: 0.01em;
	color: #3c7ef3;
	justify-content: center;
	padding: 9px 0;
}

.main_content_container.add_template_page .main_content_description .add_template_content .direct_mail_container .pick_card_buttons a:not(:last-child) {
	margin-right: 10px;
}

.main_content_container.add_template_page .main_content_description .add_template_content .input-field.subject_form {
	flex: 1;
	margin-right: 10px;
}

.main_content_container.add_template_page .main_content_description .add_template_content .input-field.subject_form input.validate {
	width: 100%;
	margin: 0;
	box-sizing: border-box;
	border: 1px solid #bdbec1 !important;
	border-radius: 4px;
}

/* Update 26.8.21 */

.main_content_description .tox.tox-tinymce {
	border-radius: 0 !important;
}

.main_content_description .tox .tox-tbtn {
	border-radius: 0 !important;
}

.main_content_description .tox .tox-edit-area__iframe {
	border-radius: 0 !important;
}

.main_content_description .tox:not([dir="rtl"]) .tox-toolbar__group:last-of-type, .main_content_description .tox:not([dir="rtl"]) .tox-toolbar__group:first-of-type {
	border-left: 0 !important;
	border-radius: 0 !important;
}

.main_content_description .tox .tox-toolbar, .main_content_description .tox .tox-toolbar__overflow, .main_content_description .tox .tox-toolbar__primary {
	border-radius: 5px 5px 0 0 !important;
	overflow: hidden;
}

.main_content_description .tox .tox-toolbar-overlord {
	width: 100%;
}

.main_content_description .templates_container.noTemplate {
	height: 300px;
	grid-auto-columns: unset;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.awesome__scroll_bar.template_display {
	padding-bottom: 0;
}

.loading__template_item {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
}

.main_content_description .templates_container.noTemplate a {
	min-width: 250px;
	justify-content: center;
	margin-top: 10px;
}

.mr20pxSpaceAlt {
	margin-right: 20px !important;
	max-width: 250px;
}

/* TODO:
 *   Need to update after production
 */

.main_content_description.activeVideoEmail .add_template_display {
	width: 0 !important;
	overflow: hidden;
	opacity: 0;
}

.main_content_description.activeVideoEmail .add_template_content {
	width: 100% !important;
}

/* Media Query Add Template Page */

@media (max-width: 1500px) {
	.main_content_description .templates_container .template .template_buttons a {
		padding: 7px;
		font-size: 11px;
		line-height: 24px;
	}
	.main_content_container h6 {
		font-size: 15px;
	}
}

@media (max-width: 1565px) {
	.main_content_container.add_template_page .main_content_description .add_template_display {
		width: calc(100% - 720px);
		padding: 0 20px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_display .smartphone_image {
		width: 100%;
	}
	.main_content_container.add_template_page .main_content_description .add_template_display .smartphone_display {
		/* width: calc(100% - 54px); */
		width: calc(100% - 51px);
		height: calc(100% - 130px);
		max-height: 100%;
		top: 69px;
		left: 28px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_display .smartphone_display .message_box .display_message {
		margin-left: 20px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content {
		/* width: 720px; */
	}
}

@media (max-width: 1366px) {
	.main_content_container.add_template_page .main_content_description .add_template_content .tabs .tab a {
		padding: 0 9px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content {
		/* width: 490px; */
		margin-right: 18px;
		padding: 9px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_display {
		/* width: 490px; */
		width: calc(100% - 490px);
	}
	body.active__minimal_sidebar .main__content_wrapper .main__content .main_content_container.add_template_page .main_content_description .add_template_content {
		width: 580px;
	}
	body.active__minimal_sidebar .main__content_wrapper .main__content .main_content_container.add_template_page .main_content_description .add_template_display {
		width: 580px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content h6 {
		font-size: 12px;
	}
	/* .main_content_container.add_template_page .main_content_description .add_template_content .input-field input{
        width: 457px;
    } */
	body.active__minimal_sidebar .main__content_wrapper .main__content .main_content_container.add_template_page .main_content_description .add_template_content .input-field input {
		width: 547px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content .tabs {
		/* width: 470px; */
	}
	.main_content_container.add_template_page .main_content_description .add_template_content .tabs .tab:nth-child(5) a {
		padding: 0 5px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content .tabs .tab:nth-child(5) span {
		margin-left: 5px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content .under_tab_buttons .input-field input {
		width: 96px;
		padding: 7px 0 9px 8px;
		font-size: 12px;
	}
	body.active__minimal_sidebar .main__content_wrapper .main__content .main_content_container.add_template_page .main_content_description .add_template_content .under_tab_buttons .input-field input {
		width: 96px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content .under_tab_buttons .input-field .select-wrapper::after {
		right: 7px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content .under_tab_buttons .upload_bitton .btn {
		padding: 0 9px 0 14px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content .under_tab_buttons .upload_bitton .btn span {
		margin-left: 6px;
		font-size: 12px;
		line-height: 18px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content .under_tab_buttons .upload_bitton .btn input {
		width: 173px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content .under_tab_buttons .save_template_button a {
		/* padding: 7px 7px; */
		padding: 12px 7px;
		font-size: 12px;
		line-height: 18px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content #video_email .under_tab_buttons .save_template_button a {
		padding: 12px 15px;
		font-size: 16px;
		line-height: 24px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content .under_tab_buttons .save_template_button a span {
		margin-left: 10px;
	}
	/* Upper tab buttons */
	.main_content_container.add_template_page .main_content_description .add_template_content .upper_tab_buttons {
		margin-bottom: 20px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content .upper_tab_buttons .input-field input {
		width: 108px;
		padding: 0 0 0 8px;
		font-size: 12px;
		/* line-height: 28px; */
		line-height: 35px;
		border-radius: 5px;
	}
	body.active__minimal_sidebar .main__content_wrapper .main__content .main_content_container.add_template_page .main_content_description .add_template_content .upper_tab_buttons .input-field input {
		width: 108px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content .upper_tab_buttons .input-field .select-wrapper::after {
		right: 15px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content .upper_tab_buttons .select_email input {
		padding: 0 0 0 8px;
		width: 164px;
		font-size: 10px;
	}
	body.active__minimal_sidebar .main__content_wrapper .main__content .main_content_container.add_template_page .main_content_description .add_template_content .upper_tab_buttons .select_email input {
		width: 164px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content .upper_tab_buttons .select_email .select-wrapper::after {
		right: 9px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content .upper_tab_buttons .subject_form input {
		font-size: 10px;
		padding: 0px 0 0px 8px;
		width: 139px;
		margin: 0 9px;
	}
	body.active__minimal_sidebar .main__content_wrapper .main__content .main_content_container.add_template_page .main_content_description .add_template_content .upper_tab_buttons .subject_form input {
		width: 139px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content .upper_tab_buttons .subject_form input::placeholder {
		font-size: 10px;
	}
	body.active__minimal_sidebar .main__content_wrapper .main__content .main_content_container.add_template_page .main_content_description .add_template_content .upper_tab_buttons .add_cc_bcc_button {
		width: 460px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content .upper_tab_buttons .add_cc_bcc_button a {
		font-size: 14px;
	}
	/* Video Email Tab Content */
	.main_content_container.add_template_page .main_content_description .add_template_content .video_email_container {
		/* width: 470px; */
		/* height: 200px; */
		height: auto;
	}
	body.active__minimal_sidebar .main__content_wrapper .main__content .main_content_container.add_template_page .main_content_description .add_template_content .video_email_container {
		/* width: 560px; */
	}
	.main_content_container.add_template_page .main_content_description .add_template_content .video_email_container p {
		font-size: 12px;
		line-height: 18px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content .video_email_container .record_upload_box {
		/* width: 190px; */
		text-align: center;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content .video_email_container .record_upload_box .record_upload_buttons a p {
		font-size: 12px;
		line-height: 20px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content .video_email_container .create_video_box {
		/* width: 250px; */
		text-align: center;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content .video_email_container .create_video_box .big_record_icon a {
		/* width: 250px; */
		height: 150px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content .video_email_container .warning_box p {
		font-size: 10px;
		line-height: 15px;
	}
	/* Voice Email Tab Content  */
	.main_content_container.add_template_page .main_content_description .add_template_content .voice_email_container {
		/* width: 470px; */
		margin-bottom: 10px;
		padding-right: 0px;
	}
	body.active__minimal_sidebar .main__content_wrapper .main__content .main_content_container.add_template_page .main_content_description .add_template_content .voice_email_container {
		width: 560px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content .voice_email_container p {
		font-size: 12px;
		line-height: 18px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content .voice_email_container .record_stop_upload_box .record_stop_upload_buttons {
		margin-left: 0px;
		margin-top: 22px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content .voice_email_container .record_stop_upload_box .record_stop_upload_buttons a p {
		font-size: 12px;
		line-height: 20px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content .voice_email_container .record_stop_upload_box .record_stop_upload_buttons .stop_button {
		margin: 0 13px;
	}
	/* Audio Player */
	.main_content_container.add_template_page .main_content_description .add_template_content .voice_email_container .audio_player {
		height: 20px;
		width: 274px;
		margin-left: 26px;
		margin-top: 50px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content .voice_email_container .audio_player .controls .timer {
		margin: 0 10px 0 5px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content .voice_email_container .audio_player .controls .timer p span {
		font-size: 12px;
		line-height: 20px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content .voice_email_container .audio_player .controls .timeline {
		height: 10px;
		width: 79px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content .voice_email_container .audio_player .controls .timeline .full_timeline {
		height: 5px;
		margin-top: 2px;
		background: rgba(60, 126, 243, 0.1);
		border-radius: 5px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content .voice_email_container .audio_player .controls .timeline .playing_timeline {
		width: 34px;
		height: 5px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content .voice_email_container .audio_player .controls .timeline .playing_timeline::after {
		content: "";
		position: absolute;
		top: -2.5px;
		right: -5px;
		width: 10px;
		height: 10px;
		border-radius: 50%;
		background: #3c7ef3;
		border: 1px solid #ffffff;
		box-shadow: 0px 4px 4px rgba(98, 54, 255, 0.2);
		z-index: 999;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content .voice_email_container .audio_player .controls .volume {
		height: 16px;
		width: 83px;
		margin: 0 3px 0 7px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content .voice_email_container .audio_player .controls .volume .volume_bar {
		width: 60px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content .voice_email_container .audio_player .controls .volume .full_volume_bar {
		width: 60px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content .voice_email_container .audio_player .controls .download {
		height: 16px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content .voice_email_container .audio_player .controls .download svg {
		height: 16px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content .voice_email_container .warning_box p {
		font-size: 10px;
		line-height: 15px;
	}
	/* Direct Mail Tab Content Css */
	.main_content_container.add_template_page .main_content_description .add_template_content .direct_mail_container {
		width: 470px;
	}
	body.active__minimal_sidebar .main__content_wrapper .main__content .main_content_container.add_template_page .main_content_description .add_template_content .direct_mail_container {
		width: 560px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content .direct_mail_container .pick_card_buttons a {
		font-size: 11px;
		text-align: center;
	}
	body.active__minimal_sidebar .main__content_wrapper .main__content .main_content_container.add_template_page .main_content_description .add_template_content .direct_mail_container .pick_card_buttons a {
		font-size: 14px;
		text-align: center;
	}
	.main_content_description .templates_container {
		padding: 15px;
		grid-gap: 15px;
	}
	.main_content_description .templates_container .template .template_buttons .edit_button {
		margin: 0 4px;
	}
	.main_content_description .templates_container .template .template_buttons span {
		margin-left: 3px;
	}
	.video_preview_side .likeArea input, .video_preview_side .likeArea textarea, .video_preview_side .likeArea select {
		height: 40px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_display .smartphone_display {
		width: calc(100% - 38px);
		height: calc(100% - 239px);
		top: 52px;
		left: 21px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_display .smartphone_display .message_box {
		min-height: calc(100% - 50px);
	}
	.main_content_container.add_template_page .main_content_description .add_template_display .smartphone_display .message_box .display_message {
		margin-left: 5px;
	}
	.display_message_body.alt {
		max-height: 140px;
	}
	.display_message_body.alt::-webkit-scrollbar {
		width: 6px;
	}
	.main_content_container .main_content_topbar h6 {
		margin: 0;
	}
}

@media (max-width: 1200px) {
	.main_content_container a {
		font-size: 12px;
	}
	.main_content_description .templates_container .template::before {
		font-size: 13px;
	}
	.main_content_description .templates_container .template h6 {
		width: calc(100% - 90px);
	}
	.main_content_topbar .search_bar {
		margin: 0 10px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content {
		height: auto;
	}
	.main_content_container.add_template_page .main_content_description .add_template_display {
		height: 100%;
	}
	.main_content_container.add_template_page .main_content_description .add_template_display .smartphone_image {
		height: 100%;
	}
	.main_content_container.add_template_page .main_content_description .add_template_display .smartphone_display {
		height: calc(100% - 75px);
		top: 35px;
		left: 14px;
		width: calc(100% - 26px);
	}
	.main_content_container h6 {
		font-size: 16px;
		line-height: 20px;
	}
	.button-set {
		margin-right: 20px;
	}
	.button-set svg {
		font-size: 25px;
	}
	/* .main_content_container.add_template_page .main_content_description .add_template_display .smartphone_display { 
        height: calc(100% - 112px); 
    } */
	.button-set p {
		display: none;
	}
	.template_buttons span {
		display: none;
	}
	.template_buttons a {
		padding: 7px 14px !important;
	}
	.add_new_btn {
		flex-wrap: wrap;
		justify-content: center;
	}
	.add_new_btn .cursor-pointer {
		margin-bottom: 10px;
	}
}


@media (max-width: 1024px) {
  .add_new.ml-5 {
	  margin-left: 15px !important;
  }
}

@media (max-width: 992px) {
  .updated_group_leadFlow_assigned .flexParent .flexChild {
	  flex-basis: calc(50% - 10px);
  }
}


@media (max-width: 768px) {
	body.active__minimal_sidebar .main__content_wrapper .main__content .main_content_container.add_template_page {
		height: auto;
	}
	.main_content_container.add_template_page .main_content_topbar {
		height: 50px;
	}
	.main_content_container.add_template_page .main_content_topbar .back_button a {
		padding: 5px 10px;
		font-size: 12px;
		line-height: 18px;
	}
	.main_content_container.add_template_page .main_content_description {
		padding: 10px 10px 21px 10px;
	}
	body.active__minimal_sidebar .main__content_wrapper .main__content .main_content_container.add_template_page .main_content_description .add_template_content {
		width: 305px;
		height: auto;
		margin-right: 10px;
	}
	body.active__minimal_sidebar .main__content_wrapper .main__content .main_content_container.add_template_page .main_content_description .add_template_display {
		width: 305px;
		height: auto;
		max-height: 523px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content h6 {
		font-size: 12px;
		line-height: 22px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content .input-field input {
		font-size: 10px;
		line-height: 18px;
		width: 247px;
	}
	body.active__minimal_sidebar .main__content_wrapper .main__content .main_content_container.add_template_page .main_content_description .add_template_content .input-field input {
		width: 247px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content .tabs {
		width: 268px;
		height: 30px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content .tabs .tab {
		height: 30px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content .tabs .tab a {
		padding: 0 3px;
		height: 30px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content .tabs .tab a svg {
		width: 10px;
		height: 10px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content .tabs .tab span {
		font-size: 8px;
		line-height: 12px;
		margin-left: 3px;
	}
	#text .tox {
		margin-top: 43px;
	}
	.tox .tox-tbtn {
		height: 24px !important;
	}
	.tox .tox-tbtn--select span {
		font-size: 10px;
		line-height: 15px;
	}
	body.active__minimal_sidebar .main__content_wrapper .main__content .main_content_container.add_template_page .tox .tox-tbtn--select {
		width: 77px !important;
		padding: 0 !important;
	}
	.tox-tbtn--select::after {
		top: 11px;
		right: 3px;
	}
	.tox-split-button::after {
		top: 11px;
		right: 3px;
	}
	/* Smartphone Css */
	.main_content_container.add_template_page .main_content_description .add_template_display .smartphone_image {
		margin: 42px auto 42px auto;
		width: 236px;
		height: 326px;
		position: relative;
	}
	.main_content_container.add_template_page .main_content_description .add_template_display .smartphone_display {
		width: 208px;
		height: 247px;
		top: 39px;
		left: 15px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_display .smartphone_display .display_number h6 {
		font-size: 12px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_display .smartphone_display .message_box .display_message {
		margin-left: 20px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_display .smartphone_display .message_box .display_message .message_time p {
		font-size: 8px;
		line-height: 14px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_display .smartphone_display .message_box .display_message .display_message_body p {
		font-size: 10px;
		line-height: 14px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content .under_tab_buttons div {
		flex-wrap: wrap;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content .under_tab_buttons .input-field input {
		width: 96px;
		padding: 7px 0 6px 8px;
		font-size: 10px;
		line-height: 15px;
	}
	body.active__minimal_sidebar .main__content_wrapper .main__content .main_content_container.add_template_page .main_content_description .add_template_content .under_tab_buttons .input-field input {
		width: 96px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content .under_tab_buttons .input-field .select-wrapper::after {
		right: 9px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content .under_tab_buttons .upload_bitton .btn {
		padding: 0 9px 0 14px;
		height: 30px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content .under_tab_buttons .upload_bitton .btn span {
		margin-left: 6px;
		font-size: 10px;
		line-height: 15px;
	}
	body.active__minimal_sidebar .main__content_wrapper .main__content .main_content_container.add_template_page .main_content_description .add_template_content .under_tab_buttons .upload_bitton .btn input {
		width: 169px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content .under_tab_buttons .save_template_button {
		margin-top: 18px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content .under_tab_buttons .save_template_button a {
		padding: 5px 5px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content .under_tab_buttons .save_template_button a svg {
		width: 20px;
		height: 20px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content .under_tab_buttons .save_template_button a span {
		margin-left: 10px;
	}
	/* Upper tab buttons */
	body.active__minimal_sidebar .main__content_wrapper .main__content .main_content_container.add_template_page .main_content_description .add_template_content .upper_tab_buttons {
		margin-bottom: 10px;
		margin-top: 10px;
		height: 30px;
	}
	body.active__minimal_sidebar .main__content_wrapper .main__content .main_content_container.add_template_page .main_content_description .add_template_content .upper_tab_buttons .input-field input {
		width: 70px;
		font-size: 8px;
	}
	body.active__minimal_sidebar .main__content_wrapper .main__content .main_content_container.add_template_page .main_content_description .add_template_content .upper_tab_buttons .input-field .select-wrapper::after {
		right: 8px;
	}
	body.active__minimal_sidebar .main__content_wrapper .main__content .main_content_container.add_template_page .main_content_description .add_template_content .upper_tab_buttons .select_email input {
		width: 130px;
		font-size: 8px;
		line-height: 28px;
		color: #546376;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content .upper_tab_buttons .subject_form input {
		font-size: 8px;
		line-height: 28px;
		padding: 5px 0 5px 8px;
		margin: 0 3px;
	}
	body.active__minimal_sidebar .main__content_wrapper .main__content .main_content_container.add_template_page .main_content_description .add_template_content .upper_tab_buttons .subject_form input {
		width: 50px;
		height: 18px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content .upper_tab_buttons .subject_form input::placeholder {
		font-size: 8px;
		line-height: 28px;
	}
	/* Video Email Tab Content */
	body.active__minimal_sidebar .main__content_wrapper .main__content .main_content_container.add_template_page .main_content_description .add_template_content .video_email_container {
		width: 285px;
		margin-bottom: 0;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content .video_email_container p {
		font-size: 10px;
		line-height: 15px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content .video_email_container .record_upload_box {
		width: 125px;
		text-align: left;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content .video_email_container .record_upload_box .record_upload_buttons {
		margin-left: 0;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content .video_email_container .record_upload_box .record_upload_buttons .record_button {
		margin-right: 10px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content .video_email_container .record_upload_box .record_upload_buttons a p {
		font-size: 10px;
		line-height: 18px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content .video_email_container .create_video_box {
		width: 140px;
		text-align: center;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content .video_email_container .create_video_box .big_record_icon a {
		width: 140px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content .video_email_container .warning_box p {
		font-size: 8px;
		line-height: 12px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content #video_email .under_tab_buttons {
		margin-top: 23px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content #video_email .under_tab_buttons .save_template_button {
		margin-top: 0;
	}
	/* Voice Tab Content  */
	.main_content_container.add_template_page .main_content_description .add_template_content .voice_email_container {
		width: 285px !important;
		height: 259px;
		margin-bottom: 52px;
		margin-top: 10px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content .voice_email_container .record_stop_upload_container {
		flex-direction: column;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content .voice_email_container p {
		font-size: 10px;
		line-height: 15px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content .voice_email_container .record_stop_upload_box .record_stop_upload_buttons {
		margin-left: 0px;
		margin-top: 23px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content .voice_email_container .record_stop_upload_box .record_stop_upload_buttons a p {
		font-size: 10px;
		line-height: 18px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content .voice_email_container .record_stop_upload_box .record_stop_upload_buttons .stop_button {
		margin: 0 22px;
	}
	/* Audio Player */
	.main_content_container.add_template_page .main_content_description .add_template_content .voice_email_container .audio_player {
		height: 20px;
		width: 264px;
		width: 100%;
		margin-left: 0px;
		margin-top: 31px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content .voice_email_container .warning_box p {
		font-size: 8px;
		line-height: 12px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content #voice .under_tab_buttons {
		width: 133px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content #voice .under_tab_buttons .save_template_button {
		margin-top: 0;
	}
	/* Direct Mail Tab Content Css */
	body.active__minimal_sidebar .main__content_wrapper .main__content .main_content_container.add_template_page .main_content_description .add_template_content .direct_mail_container {
		width: 285px;
		padding: 20px 10px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content .direct_mail_container p {
		font-size: 12px;
		line-height: 18px;
	}
	body.active__minimal_sidebar .main__content_wrapper .main__content .main_content_container.add_template_page .main_content_description .add_template_content .direct_mail_container .pick_card_buttons a {
		font-size: 8px;
		line-height: 12px;
		text-align: center;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content .direct_mail_container .pick_card_buttons a:not(:last-child) {
		margin-right: 5px;
	}
}

/* ===============================================
Add Templates Page CSS End
=============================================== */

.template .MuiSkeleton-text {
	transform: unset;
}

.width-unset {
	max-width: unset !important;
}

@media (min-width: 1500px) {
	.main_content_container.add_template_page .main_content_description .add_template_content .video_email_container .create_video_box {
		width: 510px;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content .video_email_container .create_video_box .big_record_icon a {
		width: 500px;
		height: 250px;
	}
}

@media (min-width: 2000px) {
	.main_content_divider {
		max-width: unset;
	}
	.main_content_container.add_template_page .main_content_description .add_template_content {
		flex: 1 1;
	}
	.main_content_container.add_template_page .main_content_description .add_template_display {
		flex: 1 1
	}
	.main_content_container.add_template_page .main_content_description.activeVideoEmail .add_template_content {
		flex: unset;
	}
	.add_template_display .smartphone_image {
		width: 80% !important;
	}
	.add_template_display .smartphone_image .smartphone_display {
		width: 100% !important;
	}
}

.main_content_container.add_template_page .main_content_description.activeVideoEmail .add_template_content {
    height: auto !important;
}
.main_content_description.activeVideoEmail .add_template_display{
	padding: 0px !important;
    margin: 0px !important;
}

.video___email___template___content .email-subject-video-template{
	width: 100% !important;
    margin-bottom: 10px !important;
    background: #fff !important;
    height: 50px !important;
    border-radius: 6px !important;
}
.video___email___template___content .video-email-template-personalized-field .MuiFormControl-root{
	height: 40px !important;
    margin-top: 10px !important;
}

.quick__reply_list__main__container{
	font-family: var(--poppins);
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    color: var(--gray);
    margin: 0;
    /* height: calc(100vh - 80px); */
    height: calc(100vh - 110px);
	padding-bottom :0 !important;
}

.add_template_page .marketing__module__personalized_tag .label_class_wrapper {
	height: 50px;
	align-items: center;
}

.add_template_page .marketing__module__personalized_tag__subject .label_class_wrapper {
	align-items: center;
}


/* global scroll bar for pdad */
.tdp_scroll_bar{
    height: 100%;
    padding-bottom: 4px;
    overflow-y: auto;
}
.tdp_scroll_bar::-webkit-scrollbar {
    width: 3px;
    height: 3px;
}
.tdp_scroll_bar::-webkit-scrollbar-track {
    background: rgba(60, 126, 243, 0.1);
    border-radius: 4px;
}
.tdp_scroll_bar::-webkit-scrollbar {
    scrollbar-width: thin;
}
.tdp_scroll_bar::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #949DB2;
    box-sizing: border-box;
}
.tdp_scroll_bar::-webkit-scrollbar-track {
    background: #F4F5F7;
    box-shadow: inset 1px 0px 0px rgba(148, 157, 178, 0.12);
    -webkit-box-shadow: inset 1px 0px 0px rgba(148, 157, 178, 0.12);
}