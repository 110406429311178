.new_custom_alert_v3{
    width: 100%;
    max-width: 534px;
    border-radius: 8px;
    background: #fff;
    position: relative;
    box-sizing: border-box;
}
.new_custom_alert_v3 .new_cav3_top {
    padding: 4px;
    display: flex;
    justify-content: flex-end;
}
.new_custom_alert_v3 .new_cav3_top span svg path{
    fill: #546376;
}
.new_custom_alert_v3 .new_cav3_top span{
    height: 30px;
    width: 30px;
    background: #F5F5F5;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;

}
.new_custom_alert_v3 .new_cav3_body{
    padding: 0 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.new_custom_alert_v3 .new_cav3_body_top{
    text-align: center;
    padding: 0 0 16px 0;
}
.new_custom_alert_v3 .new_cav3_body_top .new_cav3_top_icon{
    height: 76px;
    width: 76px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}
.new_custom_alert_v3 .new_cav3_body_top .new_cav3_top_title{
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.15px;
    font-weight: 600;
    margin: 12px 0 4px 0;
    color: #1C1B1F;
    word-break: break-word;
}
.new_custom_alert_v3 .new_cav3_body_top .new_cav3_top_subtitle{
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.15px;
    font-weight: 300;
    margin: 0;
    color: #1C1B1F;
    word-break: break-word;
}
.new_custom_alert_v3 .new_cav3_body_delete_section{
    padding: 14px 16px;
    border-radius: 4px;
    display: flex;
    grid-gap: 12px;
    margin-bottom: 16px;
}
.new_custom_alert_v3 .new_cav3_bdi_icon{
    flex-shrink: 0;
}
.new_custom_alert_v3 .new_cav3_bdi_icon svg {
    width: 22px;
    height: 35px;
}
.new_custom_alert_v3 .new_cav3_bdi_icon svg path{
    fill:rgba(242, 153, 74, 1)
}
.new_custom_alert_v3 .new_cav3_bdi_content_title{
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.15px;
    font-weight: 400;
    margin: 0 0 4px 0;
    color: #1C1B1F;
    word-break: break-word;
}
.new_custom_alert_v3 .new_cav3_bdi_content_p{
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.15px;
    font-weight: 300;
    margin: 0;
    color: #1C1B1F;
    word-break: break-word;
}
.new_custom_alert_v3 .new_cav3_footer{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 32px;
    grid-gap: 16px;
    position: relative;
    right: 0;
    bottom: 0;

}
.new_custom_alert_v3 .new_cav3_footer_no_btn{
    font-size: 15px;
    line-height: 26px;
    letter-spacing: 0.46px;
    font-weight: 400;
    padding: 8px 16px;
    border-radius: 4px;
    color: rgba(84, 99, 118, 1);
    cursor: pointer;
}
.new_custom_alert_v3 .new_cav3_footer_yes_btn{
    font-size: 15px;
    line-height: 26px;
    letter-spacing: 0.46px;
    font-weight: 400;
    padding: 8px 16px;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
}
/* Input Group */
.new_custom_alert_v3 .new_cav3_input_group{
    margin: 0 0 12px 0;
}
.new_custom_alert_v3 .new_cav3_input_group .new_cav3_input_error{
    font-size: 12px;
    color: #dd0436;
    padding: 4px 0 0 0;
    margin: 0;
    word-break: break-word;
}
.new_custom_alert_v3 .new_cav3_input_label{
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.15px;
    font-weight: 400;
    color: rgba(28, 27, 31, 1);
}
.new_custom_alert_v3 .new_cav3_input_label span{
    color: rgba(237, 0, 57, 1);
}
.new_custom_alert_v3 .new_cav3_input{
    height: 44px;
    border: 1px solid rgba(148, 157, 178, 0.24);
    padding: 12px;
    border-radius: 4px;
    margin: 6px 0 0 0 !important;
}
.new_custom_alert_v3 .new_cav3_input:focus{
    outline: none;
    box-shadow: none !important;
    border-color: rgba(148, 157, 178, 0.24) !important;
}
.new_custom_alert_v3 .new_cav3_label_group_span{
    display: inline-flex;
    align-items: center;
    grid-gap: 12px;
    padding: 4px 12px;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.15px;
    font-weight: 300;
    color: rgba(28, 27, 31, 1);
    cursor: pointer;
}
/* all active color */
/* warning clert color */
.new_custom_alert_warning_v3 .new_cav3_body_top .new_cav3_top_icon,
.new_custom_alert_warning_v3 .new_cav3_body_delete_section{
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #F2994A;
}
.new_custom_alert_warning_v3 .new_cav3_footer_yes_btn{
    background:rgba(242, 153, 74, 1);
}
.new_custom_alert_warning_v3 .new_cav3_footer_yes_btn:hover{
    background:rgb(233, 133, 47);
}
/* delete alert color */
.new_custom_alert_delete_v3 .new_cav3_body_top .new_cav3_top_icon,
.new_custom_alert_delete_v3 .new_cav3_body_delete_section{
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #FF264A;
}
.new_custom_alert_delete_v3 .new_cav3_footer_yes_btn{
    background:#ED0039;
}
.new_custom_alert_delete_v3 .new_cav3_footer_yes_btn:hover{
    background:#dd0436;
}
/* success alert color */
.new_custom_alert_success_v3 .new_cav3_body_top .new_cav3_top_icon,
.new_custom_alert_success_v3 .new_cav3_body_delete_section{
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #008945;
}
.new_custom_alert_success_v3 .new_cav3_footer_yes_btn{
    background:#008945;
}
.new_custom_alert_success_v3 .new_cav3_footer_yes_btn:hover{
    background:#06773e;
}
/* info alert color */
.new_custom_alert_info_v3 .new_cav3_body_top .new_cav3_top_icon,
.new_custom_alert_info_v3 .new_cav3_body_delete_section{
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #A352C2;
}
.new_custom_alert_info_v3 .new_cav3_footer_yes_btn{
    background:#A352C2;
}
.new_custom_alert_info_v3 .new_cav3_footer_yes_btn:hover{
    background:#76378f;
}