* {
    padding:0;
    margin:0;
    box-sizing: border-box;
}

/* Start Top Bar */

.cardBrodcast .cardTopBar {
    margin-top: 22px;
    padding: 20px;
    background-color: var(--white);
    border-radius: 20px;
    overflow: hidden;
}
.cardBrodcast .cardTopBar .s6 {
    padding:0;
}
.cardBrodcast .cardTopBar h3 {
    font-family: var(--poppins);
    font-size: 22px;
    line-height: 36px;
    font-weight: 500;
    padding:0;
    color:var(--dark_blue);
    margin-top:10px;    
}

/* Start Dropdown */
.cardBrodcast .cardTopBar .topBarDropdown {
    display: inline-flex;
}
.cardBrodcast .cardTopBar .topBarDropdown .MuiButtonBase-root {
    padding: 15px 26px;
    background-color: var(--light_blue) !important;
    font-family: var(--poppins);
    font-size: 16px;
    line-height: 26px;
    font-weight: 500;
    color:var(--white);
    box-shadow: none;
}
.cardBordcastCall .MuiMenu-paper .MuiMenu-list {
    width: 154px;
    padding: 10px 0;
}
.cardBordcastCall .MuiMenu-paper .MuiMenu-list .MuiListItem-root {
    padding: 10px 14px;
}
.cardBordcastCall .MuiMenu-paper .MuiMenu-list .MuiListItem-root:hover {
    background-color: var(--gray_light);
}
.cardBordcastCall .MuiMenu-paper .MuiMenu-list .MuiListItem-root .MuiListItemText-primary {
    font-family: var(--poppins);
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color:rgba(19, 49, 89, 0.35);
}

/* End Top Bar */

/* Start Accordion */
.cardBrodcast .accordionContainer .cardAccordion .col {
    padding:0;
}
.cardBrodcast .accordionContainer .cardAccordion .col.left {
    padding-right: 15px;
    padding-top:10px;
}
.cardBrodcast .accordionContainer .cardAccordion .col.right {
    padding-left: 15px;
    padding-top:10px;
}
.cardBrodcast .accordionContainer .cardAccordion .MuiAccordion-root{
    box-shadow: none;
    border-radius: 10px;

}
.cardBrodcast .accordionContainer .cardAccordion .MuiAccordion-root .MuiAccordionSummary-root {
    min-height: 52px;
    padding:0 30px;
}
.cardBrodcast .accordionContainer .cardAccordion .MuiAccordion-root .MuiAccordionSummary-root .MuiAccordionSummary-content.Mui-expanded {
    margin: 0;
 }
.cardBrodcast .accordionContainer .cardAccordion .MuiAccordion-root .MuiAccordionSummary-root:after {
    content:"";
    max-width:726px;
    width: 100%;
    height: 2px;
    background-color:var(--gray_dark);
    position:absolute;
    top:52px;
    left:30px;
}
.cardBrodcast .accordionContainer .cardAccordion .MuiAccordion-root .MuiTypography-root {
    font-family: var(--poppins);
    font-size: 20px;
    line-height: 30px;
    font-weight: 400;
    color: var(--dark_blue);
    text-transform: capitalize;
 }
 .cardBrodcast .accordionContainer .cardAccordion .MuiAccordionDetails-root{
     padding: 0 30px 30px;
     margin-top:-5px;
 }
 .cardBrodcast .accordionContainer .cardAccordion .accordionBody h4 {
    font-family: var(--poppins);
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    color: var(--dark_blue);
    text-transform: capitalize !important;
    opacity: .65;
    margin-top:20px;
 }
 .cardBrodcast .accordionContainer .cardAccordion .accordionBody p {
    font-family: var(--poppins);
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    color: var(--dark_blue);
    opacity: .5;
    margin-top:10px;
    margin-bottom: 54px;
 }
 .cardBrodcast .accordionContainer .cardAccordion .pdfFileContainer {
     width: 464px;
     height: 600px;
     overflow-y: auto;
     overflow-x: hidden;
     margin:0 auto 40px;

 }

 /* Scroll Bar */

 .cardBrodcast .accordionContainer .cardAccordion .pdfFileContainer.awesome__scroll_bar::-webkit-scrollbar-track,.cardBordcastTab.marketing-alt .MuiBox-root .tabBody .campaingData.awesome__scroll_bar::-webkit-scrollbar-track,.cardBordcastTab.marketing-alt .MuiBox-root::-webkit-scrollbar-track,.cardBordcastTab.marketing-alt .MuiBox-root .tabBody .ContactData.awesome__scroll_bar::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    border-radius: 20px;
    background-color: rgba(60, 126, 243, 0.1);
 }
 /* .cardBordcastTab.marketing-alt .MuiBox-root::-webkit-scrollbar-track {
     background-color: transparent;
 } */
 .cardBrodcast .accordionContainer .cardAccordion .pdfFileContainer.awesome__scroll_bar::-webkit-scrollbar,.cardBordcastTab.marketing-alt .MuiBox-root .tabBody .campaingData.awesome__scroll_bar::-webkit-scrollbar,.cardBordcastTab.marketing-alt .MuiBox-root::-webkit-scrollbar,.cardBordcastTab.marketing-alt .MuiBox-root .tabBody .ContactData.awesome__scroll_bar::-webkit-scrollbar {
     width: 17px;
 }
 .cardBrodcast .accordionContainer .cardAccordion .pdfFileContainer.awesome__scroll_bar::-webkit-scrollbar-thumb,.cardBordcastTab.marketing-alt .MuiBox-root .tabBody .campaingData.awesome__scroll_bar::-webkit-scrollbar-thumb,.cardBordcastTab.marketing-alt .MuiBox-root::-webkit-scrollbar-thumb,.cardBordcastTab.marketing-alt .MuiBox-root .tabBody .ContactData.awesome__scroll_bar::-webkit-scrollbar-thumb {
    background-color: var(--dark_blue);
    border: 3px solid #ebf2fe;
 }

 .cardBordcastTab.marketing-alt .MuiBox-root .tabBody .campaingData.awesome__scroll_bar::-webkit-scrollbar-thumb,.cardBordcastTab.marketing-alt .MuiBox-root::-webkit-scrollbar-thumb,.cardBordcastTab.marketing-alt .MuiBox-root .tabBody .ContactData.awesome__scroll_bar::-webkit-scrollbar-thumb {
     background-color: rgba(19, 49, 89, 0.5);
 }

 /* .cardBordcastTab.marketing-alt .MuiBox-root::-webkit-scrollbar-thumb {
    background-color: transparent;
    box-shadow: none;
    border:none;
} */


/* Start button group */

.cardBrodcast .accordionContainer .cardAccordion .accordionBody {
    width:100%;
}
 .cardBrodcast .accordionContainer .cardAccordion .accordionBody .btnGroup{
    display:flex;
    justify-content: center;
 }


 .cardBrodcast .accordionContainer .cardAccordion .accordionBody .btnGroup .cardModal button svg {
     width: 17px;
     height: 17px;
     vertical-align: middle;
     margin-right: 4px;
 }
 .cardBrodcast .accordionContainer .cardAccordion .accordionBody .btnGroup .edit, .cardBrodcast .accordionContainer .cardAccordion .accordionBody .btnGroup .delete,.cardBrodcast .accordionContainer .cardAccordion .accordionBody .btnGroup .assign {
    border-radius: 5px;
    height: 50px;
    border: none;
    font-family: var(--poppins);
    font-size: 15px;
    line-height: 22.5px;
    font-weight: 500;
    color: var(--white);
    padding: 14px 17px 14px 23px;
    background-color: transparent;
    box-shadow: none;
    border: 1px solid var(--light_blue);
    color:var(--light_blue);
    cursor: pointer;
 }
 .cardBrodcast .accordionContainer .cardAccordion .accordionBody .btnGroup .edit svg, .cardBrodcast .accordionContainer .cardAccordion .accordionBody .btnGroup .delete svg,.cardBrodcast .accordionContainer .cardAccordion .accordionBody .btnGroup .assign svg {
    width: 12px;
    height: 12px;
    vertical-align: middle;
    margin-right: 8px;
 }
 .cardBrodcast .accordionContainer .cardAccordion .accordionBody .btnGroup .assign {
     background-color: var(--light_blue) !important;
     color:var(--white)
 }
 .cardBrodcast .accordionContainer .cardAccordion .accordionBody .btnGroup .edit {
     margin:0 20px;
 }
 .cardBrodcast .accordionContainer .cardAccordion .accordionBody .btnGroup .delete {
    border: 1px solid var(--red);
    color:var(--red);
 }

 /* Start Tab Css */

 /* Start tab Head */

.cardBordcastTab.marketing-alt.marketing-alt .MuiPaper-elevation4{
    box-shadow: none !important;
    padding-bottom:30px !important;
    background-color: var(--gray_light);
 }
.cardBordcastTab.marketing-alt.marketing-alt .MuiButtonBase-root {
     height:40px;
     padding: 8px 75px;
     border-radius: 6px;
     background-color: var(--white);
     font-family: var(--poppins);
     font-size: 16px;
     line-height: 24px;
     font-weight: 500;
     color:var(--gray);
     transition: all .3s;  
 }
.cardBordcastTab.marketing-alt .MuiButtonBase-root.Mui-selected {
     /* background-color: var(--light_blue) !important; */
     background-color: var(--accent-color) !important;
     color:var(--white) !important;
 }
 .cardBordcastTab.marketing-alt .MuiButtonBase-root.Mui-selected:hover {
     background-color: var(--accent-color) !important;
     color:var(--white) !important;
 }
 .cardBordcastTab.marketing-alt .MuiTab-textColorPrimary:hover{
     background-color: var(--accent-color-opacity1) !important;
     color:var(--gray) !important;
 }
.cardBordcastTab.marketing-alt .MuiButtonBase-root.tag {
    margin: 0 21px;
 }

 /* Start tab body */

 /* Start searchBar */

.cardBordcastTab.marketing-alt .MuiBox-root{
    background-color: var(--gray_light);
    padding:0;
    /* max-height: 540px; */
    overflow-x: hidden;
    overflow-y: auto;
}
/* .cardBordcastTab.marketing-alt .MuiBox-root .tabBody {
    width: calc(100% - 20px);
} */
.cardBordcastTab.marketing-alt .MuiBox-root .tabBody .searchBar{
    display:flex;
    align-items: center;
}
.cardBordcastTab.marketing-alt .MuiBox-root .tabBody .searchBar .input-field {
    box-sizing: border-box;
    /* width: 736px!important; */
    
}
.cardBordcastTab.marketing-alt .MuiBox-root .tabBody .searchBar .input-field input{
    border:1px solid rgba(19, 49, 89, 0.15);
    border-radius: 5px;
    padding: 5px 14px;
    height: 28px;
    
}
.cardBordcastTab.marketing-alt .MuiBox-root .tabBody .searchBar .input-field input:focus {
    box-shadow: none;
}
.cardBordcastTab.marketing-alt .MuiBox-root .tabBody .searchBar .input-field input::placeholder {
    font-family: var(--poppins);
    font-size: 16px;
    line-height: 30px;
    font-weight: 400;
    color:rgba(19, 49, 89, 0.15);
    text-transform: capitalize;
}
.cardBordcastTab.marketing-alt .MuiBox-root .tabBody .searchBar .searchIcon {
    position: relative;
    cursor:pointer;
}
.cardBordcastTab.marketing-alt .MuiBox-root .tabBody .searchBar .searchIcon svg {
    width:20px;
    height:20px;
}
.cardBordcastTab.marketing-alt .MuiBox-root .tabBody .searchBar .searchIcon:before {
    content: "";
    width: 1px;
    height: 32px;
    /* background-color: rgba(19, 49, 89, 0.35); */
    position: absolute;
    top: -6px;
    left: -10px;

}


/* Start Info */
.cardBordcastTab.marketing-alt .MuiBox-root .tabBody .campaingData {
    width: calc(100% - 10px);
    /* max-height: 374px; */
    padding: 10px 10px 0;
    border-radius: 5px;
    background-color: var(--white);
}
.cardBordcastTab.marketing-alt .MuiBox-root .tabBody .campaingData label {
    display: block;
    padding: 10px;
    border-radius: 5px;
    background-color: var(--gray_light);
    margin-bottom: 10px;
    text-align: left;
    position: relative;
}
.cardBordcastTab.marketing-alt .MuiBox-root .tabBody .campaingData label [type="checkbox"].filled-in:not(:checked)+span:not(.lever):after{
    border: 1px solid var(--light_blue);
    width: 20px;
    height: 20px;
    border-radius: 5px;
}
.cardBordcastTab.marketing-alt .MuiBox-root .tabBody .campaingData label [type="checkbox"].filled-in:checked+span:not(.lever):after {
    background-color: var(--light_blue);
    border:1px solid var(--light_blue);
    border-radius: 5px;
}
.cardBordcastTab.marketing-alt .MuiBox-root .tabBody .campaingData label .campaingName {
    font-family: var(--poppins);
    font-size: 16px;
    line-height: 22px;
    font-weight: 300;
    color:#8896A8;
    text-transform: capitalize;
}
/* Start Table Info */
.cardBordcastTab.marketing-alt .MuiBox-root .tabBody .ContactData{
  background-color: var(--white);
  padding: 20px 20px 10px;
  width:calc(100% - 10px);
  max-height: 350px;
}
.cardBordcastTab.marketing-alt .MuiBox-root .tabBody .ContactData .contact table tr {
    border-bottom: none;

}
.cardBordcastTab.marketing-alt .MuiBox-root .tabBody .ContactData .contact table tbody tr {
    border-bottom: 10px solid var(--white);
}
.cardBordcastTab.marketing-alt .MuiBox-root .tabBody .ContactData .contact table th {
    padding: 10px 15px;
    font-family: var(--poppins);
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
    color:var(--gray);
    text-transform: capitalize;
}
.cardBordcastTab.marketing-alt .MuiBox-root .tabBody .ContactData .contact table td, .cardBordcastTab.marketing-alt .MuiBox-root .tabBody .ContactData .contact table td label {
    padding: 10px 15px;
    font-family: var(--poppins);
    font-size: 16px;
    line-height: 22px;
    font-weight: 300;
    color:#99A6B9;
    background-color: var(--gray_light);
}
.cardBordcastTab.marketing-alt .MuiBox-root .tabBody .ContactData .contact table td label {
    padding: 10px 0;
}
.cardBordcastTab.marketing-alt .MuiBox-root .tabBody .ContactData .contact table td:first-child {
    border-radius: 6px 0 0 6px;
}
.cardBordcastTab.marketing-alt .MuiBox-root .tabBody .ContactData .contact table td:nth-child(2) {
    border-right: 2px solid var(--white);
    border-left: 2px solid var(--white);
}
.cardBordcastTab.marketing-alt .MuiBox-root .tabBody .ContactData .contact table td:last-child {
    border-radius: 0 6px 6px 0;
}

.cardBordcastTab.marketing-alt .MuiBox-root .tabBody .ContactData .contact table td label [type="checkbox"].filled-in:not(:checked)+span:not(.lever):after {
    border: 1px solid var(--light_blue);
    width: 20px;
    height: 20px;
    border-radius: 5px;
}
.cardBordcastTab.marketing-alt .MuiBox-root .tabBody .ContactData .contact table td label [type="checkbox"].filled-in:checked+span:not(.lever):after {
    background-color: var(--light_blue);
    border:1px solid var(--light_blue);
    border-radius: 5px;
}

/* Start ModalBody Footer */

.cardBordcastTab.marketing-alt .MuiBox-root .tabBody .select {
    padding: 30px 0 53px;
    background-color: var(--gray_light);
    display:flex;
    flex-direction: column;
    justify-content: center;
}
.cardBordcastTab.marketing-alt .MuiBox-root .tabBody .select h3 {
    font-family: var(--poppins);
    font-size: 20px;
    line-height: 30px;
    font-weight: 400;
    color:#8896A8;
    text-transform: capitalize;
    text-align: center;
    color:var(--dark_blue);
    margin-bottom:17px;
}
.cardBordcastTab.marketing-alt .MuiBox-root .tabBody .select .buttongroup {
    display: flex;
    justify-content: center;
    margin-bottom: 13px;
}
.cardBordcastTab.marketing-alt .MuiBox-root .tabBody .select .buttongroup .btn {
    padding: 14px 20px 13px;
    background-color: var(--white);
    font-family: var(--poppins);
    font-size: 15px;
    line-height: 22.5px;
    font-weight: 500;
    color:var(--light_blue);
    text-transform: capitalize;
    box-shadow: none;
    width: 179px;
    height: 50px;
}
.cardBordcastTab.marketing-alt .MuiBox-root .tabBody .select .buttongroup .btn.left {
    border-radius: 6px 0 0 6px;
}
.cardBordcastTab.marketing-alt .MuiBox-root .tabBody .select .buttongroup .btn.right {
    border-radius: 0 6px 6px 0;
}
.cardBordcastTab.marketing-alt .MuiBox-root .tabBody .select .buttongroup .btn.active {
    background-color: rgba(60, 126, 243, 0.1);
}

.cardBordcastTab.marketing-alt .MuiBox-root .tabBody .select h4 {
    font-family: var(--poppins);
    font-size: 20px;
    line-height: 30px;
    font-weight: 400;
    color:var(--gray);
    text-align: center;
    text-transform: capitalize;
}
.cardBordcastTab.marketing-alt .MuiBox-root .tabBody .select .date {
    display:flex;
    justify-content: center;
    margin-top:17px
}
.cardBordcastTab.marketing-alt .MuiBox-root .tabBody .select .date {
    font-family: var(--poppins);
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color:var(--dark_blue);
    text-transform: capitalize;
    padding: 0 10px;
}
.cardBordcastTab.marketing-alt .MuiBox-root .tabBody .select .date input {
    border: 1px solid var(--gray_dark);
    min-width: 200px;
    height: unset;
    border-radius: 5px;
    padding:8px 5px;
    margin: 0 10px;
    opacity: .7;
}
.cardBordcastTab.marketing-alt .MuiBox-root .tabBody .select .date input:focus {
    box-shadow: none;
}
.cardBordcastTab.marketing-alt .MuiBox-root .tabBody .select .date input::before {
    border:none !important;
}

.cardBordcastTab.marketing-alt .MuiBox-root .tabBody .select .date .MuiInput-underline:after {
    border:none ;
}
.cardBordcastTab.marketing-alt .MuiBox-root .tabBody .select .date .MuiInput-underline:before {
    border:none ;
}



/* Update Styles */
.globalFormControlItem {
    margin: 0;
    border: 1px solid rgba(19, 49, 89, 0.15);
}

.globalFormControlItem,
.globalFormControlItem {
    margin: 0 !important;
    border: 1px solid rgba(19, 49, 89, 0.15) !important;
    border-radius: 6px !important;
    height: 40px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    box-shadow: unset !important;
}

input.globalFormControlItem.alt {
    width: 140px;
    margin-right: 10px !important;
    margin-left: 10px !important;
}

.senderEmailWrapper {
    align-items: center;
    /*margin-top: 10px;*/
    /*margin-left: 20px;*/
    margin-bottom: 20px;
}

.senderEmailWrapper .globalFormControlItem, .senderEmailWrapper .template-bcst {
    margin-left: 10px !important;
}
.senderEmailWrapper .MuiInput-underline:before,
.senderEmailWrapper .MuiInput-underline:after {
    display: none;
}

.popup-tab.popup-tab .m-portlet__head ul.react-tabs__tab-list li.react-tabs__tab {
    min-height: 40px;
    height: 40px;
    background: #f8f8f8 !important;
    border: 1px solid #c5c5c5e3 !important;
    border-radius: 6px;
    margin: 0 6px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.popup-tab.popup-tab .m-portlet__head ul.react-tabs__tab-list li.react-tabs__tab:hover {
    background: var(--accent-color-opacity1) !important;
}
.popup-tab.popup-tab .m-portlet__head ul.react-tabs__tab-list li.react-tabs__tab--selected {
    /* background: #3c7ef3 !important; */
    background: var(--accent-color) !important;
    color: #fff !important;
}
.popup-tab.popup-tab .m-portlet__head ul.react-tabs__tab-list li.react-tabs__tab--selected:hover {
    /* background: #3c7ef3 !important; */
    background: var(--accent-color) !important;
    color: #fff !important;
}
.globalBroadcastModal .MuiBox-root .tabBody .campaingData {
    position: relative;
}
@media(max-width: 1920px) {
 
    .globalBroadcastModal.cardBordcastTab.marketing-alt .MuiPaper-elevation4 { 
        padding-bottom: 0px !important; 
    }

    .globalBroadcastModal .MuiButtonBase-root {
        height: 40px;
        min-height: 40px;
    }
    .globalBroadcastModal .MuiBox-root .tabBody .campaingData label {
        padding: 3px 10px;
        margin-bottom: 3px;
    }
    .globalBroadcastModal .MuiBox-root .tabBody .campaingData { 
        /* max-height: calc(100% - 110px); */
        padding: 10px 10px 0;
    }
    .globalBroadcastModal .MuiBox-root .tabBody .campaingData label .campaingName {
        font-size: 13px;
        line-height: 10px;
    }

    .globalBroadcastModal .MuiBox-root .tabBody .campaingData label .campaingName {
        font-size: 13px;
        line-height: 10px;
        display: inline-flex;
        align-items: center;
    }

    .globalBroadcastModal.cardBordcastTab.marketing-alt .MuiBox-root .tabBody .campaingData label [type="checkbox"].filled-in:not(:checked)+span:not(.lever):after {
        top: 4px;
    }

    .globalBroadcastModal [type="checkbox"].filled-in:checked+span:not(.lever):before {
        top: 3px;
    }
    .globalBroadcastModal [type="checkbox"].filled-in:not(:checked)+span:not(.lever):after,
    .globalBroadcastModal [type="checkbox"].filled-in:checked+span:not(.lever):after,
    .globalBroadcastModal.cardBordcastTab.marketing-alt .MuiBox-root .tabBody .campaingData label [type="checkbox"].filled-in:not(:checked)+span:not(.lever):after {
        top: 4px;
    }

    .globalBroadcastModal.cardBordcastTab.marketing-alt .MuiBox-root .tabBody .searchBar .input-field {
        margin: 0 !important;
    }


    .globalBroadcastModal .popup-tab .m-portlet__head ul.react-tabs__tab-list li.react-tabs__tab:last-child {
        border-right: 0;
        height: 30px;
        line-height: 30px;
    }
    
    .globalBroadcastModal .campaingData.awesome__scroll_bar + .row {
        margin: 0;
    }
    /* .globalBroadcastModal .react-swipeable-view-container {
        flex-direction: column !important;
    } */


   


}
/* Responsive Design */

@media only screen and (max-width: 1024px) {

    /* Accordion */
    .cardBrodcast .accordionContainer .cardAccordion.row {
       margin-bottom:0;
    }
    .cardBrodcast .cardTopBar {
        padding: 20px 10px;
        border-radius: 10px;
        margin: 20px 0 0;
    }
    .cardBrodcast .cardTopBar .topBarDropdown .MuiButtonBase-root {
        padding: 11px 15px
    }
    .cardBrodcast .accordionContainer .cardAccordion .col.left {
        padding-right: 5px;
        padding-top:10px;
    }
    .cardBrodcast .accordionContainer .cardAccordion .col.right {
        padding-left: 5px;
        padding-top:10px;
    }
    .cardBrodcast .accordionContainer .cardAccordion .MuiAccordion-root .MuiAccordionSummary-root:after {
        width: 295px;
    }
    .cardBrodcast .accordionContainer .cardAccordion .MuiAccordion-root .MuiAccordionSummary-root {
        padding: 0 10px;
    }
    .cardBrodcast .accordionContainer .cardAccordion .MuiAccordion-root .MuiAccordionSummary-root:after {
        left:10px;
    }
    .cardBrodcast .accordionContainer .cardAccordion .MuiAccordionDetails-root {
        padding: 0 10px 18px;
    }

    /* Accordion Body */
    .cardBrodcast .accordionContainer .cardAccordion .accordionBody .btnGroup .edit, .cardBrodcast .accordionContainer .cardAccordion .accordionBody .btnGroup .delete, .cardBrodcast .accordionContainer .cardAccordion .accordionBody .btnGroup .assign {
        padding: 9px 10px 13px;
        height: 40px;
        font-size: 12px;
        line-height: 18px;
    }
    .cardBrodcast .accordionContainer .cardAccordion .accordionBody .btnGroup .edit {
        margin: 0 10px;
    }
    .cardBrodcast .accordionContainer .cardAccordion .pdfFileContainer {
        width:275px;
    }

    /* Tab */
    .cardBordcastTab.marketing-alt .MuiPaper-elevation4 {
        padding-bottom: 15px !important;
    }
    .globalModalWrapper .globalModalTitle {
        padding: 0 10px;
    }
    .cardBordcastTab.marketing-alt .MuiButtonBase-root.tag {
        margin: 0 10px;
    }
    .cardBordcastTab.marketing-alt .MuiBox-root .tabBody {
        width: calc(100% - 30px) !important;
    }
    .cardBordcastTab.marketing-alt .MuiBox-root .tabBody .searchBar .input-field {
        box-sizing: border-box;
        width: 94% !important;
    }
    .cardBordcastTab.marketing-alt .MuiBox-root .tabBody .searchBar .input-field input {
        margin-bottom: 8px;
    }
    .cardBordcastTab.marketing-alt .MuiBox-root .tabBody .select {
        padding:30px 0 45px;
    }
    .cardBordcastTab.marketing-alt .MuiBox-root .tabBody .select .date input {
        min-width: 154px;
    }
    .cardBordcastTab.marketing-alt .MuiBox-root .tabBody .campaingData label [type="checkbox"].filled-in:not(:checked)+span:not(.lever):after {
        width:10px;
        height:10px;
        border-radius: 5px;
    }

    /* Font sizes */
    .cardBrodcast .cardTopBar h3 {
        font-size: 20px;
        line-height: 30px;
    }
    .cardBrodcast .cardTopBar .topBarDropdown .MuiButtonBase-root {
        font-size: 12px;
        line-height: 18px;
    }
    .cardBrodcast .accordionContainer .cardAccordion .MuiAccordion-root .MuiTypography-root {
        font-size: 16px;
        line-height: 30px;
    }
    .cardBrodcast .accordionContainer .cardAccordion .accordionBody h4 {
        font-size: 12px;
        line-height: 22px;
    }
    .cardBrodcast .accordionContainer .cardAccordion .accordionBody p  {
        font-size: 10px;
        line-height: 18px;
        margin-bottom: 10px;
    }
    .globalModalWrapper .sub_title h2 {
        font-size: 20px;
        line-height: 30px;
    }
    .cardBordcastTab.marketing-alt .MuiButtonBase-root {
        font-size: 12px;
        line-height: 20px;
    }
    .cardBordcastTab.marketing-alt .MuiBox-root .tabBody .campaingData label .campaingName {
        font-size: 12px;
        line-height: 20px;
    }
    .cardBordcastTab.marketing-alt .MuiBox-root .tabBody .select h3,.cardBordcastTab.marketing-alt .MuiBox-root .tabBody .select h4 {
        font-size: 16px;
        line-height: 24px;
    }
    .cardBordcastTab.marketing-alt .MuiBox-root .tabBody .select .buttongroup .btn {
        font-size: 12px;
        line-height: 18px;
    }
    .cardBordcastTab.marketing-alt .MuiBox-root .tabBody .select .date {
        font-size: 12px;
        line-height: 18px;
        margin-bottom: -10px;
    }
    .cardBordcastTab.marketing-alt .MuiBox-root .tabBody .select .date h3 {
        margin-top:10px;
    }
    .globalModalWrapper .massage_send_close_button .send_massage, .globalModalWrapper .massage_send_close_button .close_btn {
        font-size: 16px;
        line-height: 24px;
    }
    .cardBordcastTab.marketing-alt .MuiBox-root .tabBody .ContactData .contact table th,.cardBordcastTab.marketing-alt .MuiBox-root .tabBody .ContactData .contact table td, .cardBordcastTab.marketing-alt .MuiBox-root .tabBody .ContactData .contact table td label {
        font-size: 12px;
        line-height: 20px;
    }
}

.select-option-data{
    max-height: 250px;
}

.global__custom_select .MuiFormControl-root.form-control {
    height: 40px;
    width: auto;
    border: 1px solid #E5E8EF;
    box-sizing: border-box;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 22px 10px 20px;
    margin-right: 10px;
}

.MuiInput-underline:before, .MuiInput-underline:after {
    display: none;
}

.day-select-message{
    font-size: 10px;
    color: #d00;
    position: absolute;
    top: 100%;
    width: 100%;
    left: 39px;
    text-align: left;
}
.position-relative{
    position: relative;
}
.selects_fields.subject_input{
    margin: 0 5px !important
}
.left-6{
    left: 6px
}


/* custom css */
.cardBordcastTab .card-broadcast-campaign-search{
    height: 3rem !important;
    width: 100% !important;
}
.cardBordcastTab .card-broadcast-campaign-search-content{
    position: relative;
    width: 100% !important;
}

.cardBordcastTab .card-broadcast-campaign-search-content .searchIcon {
    position: absolute !important;
    right: 24px;
    top: 13px;
}
.date-picker-at-campaign-quick-broadcast .MuiButtonBase-root.MuiIconButton-root{
    padding: 10px !important; 
}

.upload-image-container-update{
    position: relative;
}
.upload-image-container-update .upload-mms-hints{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: justify;
    height: 100%;
    top: 0;
    background-color: #ffffffa8;
    padding: 0px 10px;
}
.campaign__setting__email .g__attachment__content{
    margin: 0 36px;
}
.campaign__setting__email .quick_reply_hanlde__wr__v2{
    display: flex;
    grid-gap: 16px;
    align-items: center;
    width: 100%;
    margin: 0 0 0 11px;
}
.campaign__setting__email .quick_reply_hanlde_button{
    z-index: 9999;
    position: relative;
    width: 168px;
    height: 42px;
    margin: 0 0 0 11px;
    border: 1px solid #E5E8EF;
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: var(--dark_blue);
    display: inline-block;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 8px;
}
.campaign__setting__email .each__edit__attachment{
    margin: 10px 0 ;
    padding: 5px 10px;
    border: 1px solid #eee;
    border-radius: 0.25rem;
    font-size: 12px;
    font-weight: 500;
}
.campaign__setting__email .each__edit__attachment__delete{
    background-color: #2bbbad;
    padding: 1px 5px;
    font-size: 8px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    line-height: 18px;
    height: 18px;
    margin: 0 0 0 6px;
    border-radius: 2px;
    cursor: pointer;
    color: #fff;
    font-weight: 700;
}
.campaign__setting__email .templates___body__back_button{
    border: 1px solid #ddd;
    padding: 5px;
    border-radius: 4px;
    cursor: pointer;
    max-width: 90px;
    text-align: center;
    margin: 0 0 7px 4px;
}
.campaign_setting_template_container{
    overflow-y: scroll;
    height: calc(100% - 20px);
}


/* inbox css for signeture design */

.MarketingModule-container-parent-class .user_signature_content table h1,
.MarketingModule-container-parent-class .user_signature_content table h2,
.MarketingModule-container-parent-class .user_signature_content table h3,
.MarketingModule-container-parent-class .user_signature_content h4,
.MarketingModule-container-parent-class .user_signature_content table h5,
.MarketingModule-container-parent-class .user_signature_content table h6,
.MarketingModule-container-parent-class .chat__text-wrap .gmail_quote table h1,
.MarketingModule-container-parent-class .chat__text-wrap .gmail_quote table h2,
.MarketingModule-container-parent-class .chat__text-wrap .gmail_quote table h3,
.MarketingModule-container-parent-class .chat__text-wrap .gmail_quote table h4,
.MarketingModule-container-parent-class .chat__text-wrap .gmail_quote table h5,
.MarketingModule-container-parent-class .chat__text-wrap .gmail_quote table h6{
  font-size: 16px;
  font-weight: 500;
  margin: 0 !important;
  line-height: 24px;
  word-break: break-word;
}
.MarketingModule-container-parent-class .user_signature_content table p,
.MarketingModule-container-parent-class .chat__text-wrap .gmail_quote table p{
  font-size: 14px !important;
  font-weight: 400 !important;
  margin: 0 !important;
  line-height: 28px !important;
  color: #80868c !important;
  word-break: break-word;

}

.MarketingModule-container-parent-class .user_signature_content table img,
.MarketingModule-container-parent-class .chat__text-wrap .gmail_quote table img{
  width: 110px;
  object-fit: contain;
}


.separetor_div {
    margin: 0 0 15px 0;
    border-radius: 5px;
    background: #252526;
    color: #fff;
    padding: 15px 20px;
}

.MarketingModule-container-parent-class .add_template_content .add__unsubscried__text{
    display: flex;
    align-items: center;
    gap: 5px;
}

.campaign__setting__email .marketing__module__sms_modal {
    width: 168px;
}

.campaign__setting__email .marketing__module__sms_modal .label_class_wrapper {
    height: 40px;
    border: 1px solid #E5E8EF;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.campaign__setting__email .marketing__module__email_modal .label_class_wrapper {
    height: 45px;
    border: 1px solid #E5E8EF;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.campaign__setting__email .marketing__module__email_modal_body .label_class_wrapper {
    height: 42px;
    border: 1px solid #E5E8EF;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.add_activity_text_field{
    height: 40px;
    width: auto;
    border: 1px solid #E5E8EF !important;
    box-sizing: border-box;
    border-radius: 5px !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    padding: 22px 10px 20px !important;
    margin-right: 10px !important;
}

.cardBordcastTab.marketing-alt.globalBroadcastModal input[type="text"] {
    margin: 0 !important;
}