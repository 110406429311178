.p_g_u_l_c_d_main_w{
    padding: 10px;
    max-height: 400px;
    width: 300px;
}
.p_g_u_l_c_d_main_w .search__input{
    border: 1px solid #ddd;
    border-radius: 6px;
    padding: 2px 10px;
    margin-bottom: 10px;
}
.p_g_u_l_c_d_main_w .empty__containner{
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    color: #000;
}
.p_g_u_l_c_d_main_w .pype_g_u_l_c_dd{
    width: 100%;
    padding-bottom: 5px !important;
    max-height: 400px;
    /* height: calc(100% - 100px) !important; */
}
.p_g_u_l_c_d_main_w .pype_g_u_l_c_dd .pgwptc__group__each__item{
    width: 100%;
    padding: 10px 30px 10px 0px;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: space-between;
}
.custom_menu_option_container .avater__name__sub__user__list{
    background: #ddd;
    padding: 3px 5px;
    border-radius: 4px;
    margin-right: 5px;
    font-size: 12px;
}

.pypepro_global__custom__field__grouped__wraper .custom_menu_option_container .label_class_wrapper{
    text-transform: capitalize;
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 6px 12px;
    align-items: center;
    cursor: pointer;
}
.pypepro_global__custom__field__grouped__wraper .custom_menu_option_container .label_class_wrapper span{
    display: inline-flex;
}
/* .pypepro_global__custom__field__grouped__wraper .custom_menu_option_container .label_class_wrapper .iv2__su__single{
    display: block !important;
    width: 98%;
} */
#pype_g_u_l_c_dd_w_id .p_g_u_l_c_d_main_w.awesome__scroll_bar::-webkit-scrollbar{
    width: 8px;
}
